<script type="ts">
  /* Global dependencies */
  import { onDestroy, onMount } from "svelte";
  import Router, { replace } from "svelte-spa-router";

  /* GSFT dependencies */
  import { me } from "./api/auth";
  import { getDictionaries } from "./api/dictionaries";
  import { getActiveGrandSlam } from "./api/grand-slams";
  import routes from "./routes";
  import { dictionaries } from "./store/dictionaries";
  import { activeGrandSlam } from "./store/active-grand-slam";
  import { token } from "./store/token";
  import type { GrandSlam } from "./types/grand-slams";

  let isLogged: boolean;
  let clear: NodeJS.Timeout;

  onMount(() => {
    heartbit();
    clear = setInterval(() => {
      heartbit();
    }, 60000);
  });

  onDestroy(() => {
    clearInterval(clear);
  });

  const heartbit = () => {
    if ($token) {
      me();
    }
  };

  token.subscribe((value) => {
    isLogged = !!value;
    if (value) {
      getActiveGrandSlam().then((grandSlam: GrandSlam) => {
        $activeGrandSlam = grandSlam;
      });
      getDictionaries().then((res) => {
        $dictionaries = res;
      });
    }
  });

  const conditionsFailed = (event) => {
    if (isLogged) {
      replace("#/");
    } else {
      replace("#/login");
    }
  };
</script>

<Router {routes} on:conditionsFailed={conditionsFailed} />
