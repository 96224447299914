<script type="ts">
  /* Global dependencies */
  import { createEventDispatcher, SvelteComponent } from "svelte";
  import { Button, Form, FormGroup, Input, Label } from "sveltestrap";
  import Time from 'svelte-time';
  /* GSFT dependencies */
  import ColumnHeader from "./ColumnHeader.svelte";
  import ColumnContent from "./ColumnContent.svelte";
  import type { ChairUmpire, ViolationRecord } from "../../types/violations";
  import { DRAFT, OPEN, COMPLETE, CLOSED } from "../../types/violations";
  import { updateViolation, moveToStatus, deleteViolation } from "../../api/violations";
  import type { GetDictionariesResult } from "../../types/dictionaries";
  import type { GsCourt } from "../../types/courts";
  import RecordForm from "./RecordForm.svelte";
  import { notifications } from "../../helpers/notifications";
  import { getViolationShortName } from "../../helpers/format";

  import {
    user,
    isChairUmpire,
    isGrandSlamAdmin,
    isGrandSlamSupervisor,
    isSuperAdmin,
  } from "../../store/user";
  import ConfirmationModal from "../ConfirmationModal.svelte";

  /* Props */
  export let chairUmpires: Array<ChairUmpire>;
  export let courts: Array<GsCourt>;
  export let dictionaries: GetDictionariesResult;
  export let selectedRecord: ViolationRecord;

  /* Component */
  const dispatch = createEventDispatcher();
  let recordFormComponent: SvelteComponent;
  let openClearConfirmation: boolean = false;
  let openMoveConfirmation: boolean = false;
  let openDeleteConfirmation: boolean = false;
  let сonfirmationEvent: CustomEvent;
  let isSubmitting = false;
  let isFormModified = false;

  const submitViolation = async (event: CustomEvent) => {
    const { data, saveAndMove, saveAndBack } = event.detail;
    isSubmitting = true;
    try {
      if (isFormModified && !saveAndBack) {
        const response = await updateViolation(data);
        selectedRecord = { ...response };
        notifications.success("Violation record updated");
        dispatch("update", { record: selectedRecord });
      }
      if (saveAndBack) {
        data.status = "DRAFT";
        const response = await updateViolation(data);
        selectedRecord = { ...response };
        notifications.success("Case was moved to DRAFT");
        dispatch("update", { record: selectedRecord });
      }

      if (saveAndMove) {
        let nextStatus;
        if (selectedRecord.status === DRAFT) {
          nextStatus = OPEN;
        } else if (selectedRecord.status === OPEN) {
          nextStatus = COMPLETE;
        } else if (selectedRecord.status === COMPLETE) {
          nextStatus = CLOSED;
        }

        if (nextStatus) {
          const response = await moveToStatus({
            violationRecordId: selectedRecord.id,
            targetStatus: nextStatus,
          });
          selectedRecord = { ...response };
          selectedRecord.status = nextStatus;
          
          dispatch("update", {
            record: selectedRecord,
          });
          notifications.success(`Case was moved to ${nextStatus}`);
        }
      }
    } catch (responseErrors) {
      let errors = [];
      if (responseErrors) {
        for (const error in responseErrors) {
          if (responseErrors[error]) {
            errors.push(responseErrors[error]);
          }
        }
      }
      if (Array.isArray(errors) && errors.length > 0) {
        notifications.danger("Something went wrong: " + errors.join(", "));
      } else {
        notifications.danger("Something went wrong");
      }
    } finally {
      isSubmitting = false;
      сonfirmationEvent = null;
    }
  };

  const deleteSelectedRecord = async() => {
    
    const response = await deleteViolation({
        violationRecordIds: [selectedRecord.id]
    });
    dispatch("delete", {records: [selectedRecord]});
    notifications.success(`Case was deleted`);
  };

  $: showButtons =
    ($isChairUmpire && selectedRecord.status === DRAFT) ||
    (($isGrandSlamSupervisor || $isSuperAdmin) &&
      [DRAFT, OPEN, COMPLETE, CLOSED].includes(selectedRecord.status));

  $: showDeleteButton = 
    (($isGrandSlamSupervisor || $isSuperAdmin || $isGrandSlamAdmin) &&
    [DRAFT, OPEN].includes(selectedRecord.status));

  const moveButtonLabelToStatusMapping = {
    DRAFT: "Open",
    OPEN: "Completed",
    COMPLETE: "Closed",
  };
  $: moveButtonLabel = [
    isFormModified ? "Save and" : "",
    "Move to",
    selectedRecord.status === "DRAFT" ? "Supervisor" : moveButtonLabelToStatusMapping[selectedRecord.status] || "",
  ]
    .filter((label) => !!label)
    .join(" ");
</script>

<ConfirmationModal
  bind:open={openClearConfirmation}
  on:proceed={recordFormComponent.discardChanges}
/>

<ConfirmationModal
  bind:open={openDeleteConfirmation}
  on:proceed={() => deleteSelectedRecord()}
/>

<ConfirmationModal
  bind:open={openMoveConfirmation}
  on:proceed={() => submitViolation(сonfirmationEvent)}
/>

<ColumnHeader style="display:flex;flex-flow:row nowrap;align-items:center;height:57.39px;">
  <strong class="record-name me-2" title={getViolationShortName(selectedRecord)}
    >{getViolationShortName(selectedRecord)}</strong
  >
  {#if showButtons}
    {#if showDeleteButton}
      <Button
        color="danger"
        class="btn-sm float-end me-2 text-nowrap"
        form="recordForm"
        value="delete"
        disabled={isSubmitting}
        on:click={() => {openDeleteConfirmation = true}}>Delete</Button
        >
    {/if}
    {#if selectedRecord.status !== CLOSED}
      <Button
        color="success"
        class="btn-sm float-end me-2 text-nowrap"
        form="recordForm"
        value="move"
        disabled={isSubmitting}>{moveButtonLabel}</Button
      >
    {/if}
    {#if selectedRecord.status === OPEN}
      <Button
        color="warning"
        class="btn-sm float-end me-2 text-nowrap"
        form="recordForm"
        value="back"
        disabled={isSubmitting}
      >
        {#if isFormModified}
          Save and
        {/if}
        Send Back to Draft
      </Button>
    {/if}
    <Button
      color="light"
      class="btn-sm float-end me-2 text-nowrap"
      on:click={() => (openClearConfirmation = true)}
      disabled={isSubmitting || !isFormModified}
    >
      Cancel
    </Button>
    <Button
      color="primary"
      class="btn-sm float-end me-2 text-nowrap"
      form="recordForm"
      value="save"
      disabled={isSubmitting || !isFormModified}
    >
      Save
    </Button>
  {/if}
</ColumnHeader>
<ColumnContent style="width:100%; height:calc(100vh - 166px);">
  <!-- make sure the form rerenders to reset its initial state -->
  {#key selectedRecord.updatedDate}
    <RecordForm
      {selectedRecord}
      {courts}
      {chairUmpires}
      {dictionaries}
      bind:isFormModified
      on:submit={(e) => {
        const { data, saveAndMove, saveAndBack } = e.detail;
        if (saveAndMove || saveAndBack) {
          openMoveConfirmation = true;
          сonfirmationEvent = e;
        } else {
          submitViolation(e);
        }
      }}
      bind:this={recordFormComponent}
    />
    
    <div class="mx-3 p-3 updated-date">
      {#if selectedRecord.createdDate}
      <div>
      <label>Created: </label>
        <Time
        timestamp={new Date(selectedRecord.createdDate)}
        format="MMM D, YYYY hh:mm A"/>
      </div>
      {/if}
      <div>
      <label>Updated: </label>
      <Time
      timestamp={new Date(selectedRecord.updatedDate)}
      format="MMM D, YYYY hh:mm A"/>
      </div>
    </div>
  {/key}
  
</ColumnContent>

<style>
  .record-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-grow: 1;
  }
  .updated-date{
    background-color: #f5f5f5;
    border-radius: 4px;
    border: 1px solid var(--gsft-border-color);
    font-size:13px;
  }
  .updated-date label{
    font-weight: 700;
    display: inline;
  }
  .updated-date time{
    display: inline;
  }
</style>
