<script type="ts">
  /* Global dependencies */
  import { createEventDispatcher } from 'svelte';
  import { Button } from 'sveltestrap';
  import Time from 'svelte-time';
  import Flatpickr from "svelte-flatpickr";

  /* GSFT dependencies */
  import ColumnHeader from './ColumnHeader.svelte';
  import ColumnContent from './ColumnContent.svelte';
  import type { ViolationRecord } from '../../types/violations';
  import { getViolationShortName } from '../../helpers/format';
  import { getViolationStatusClassName } from '../../helpers/format';
  import { deleteViolation } from "../../api/violations";
  import { DRAFT, OPEN, COMPLETE, CLOSED } from "../../types/violations";
  import { notifications } from "../../helpers/notifications";
  import type { GetDictionariesResult } from "../../types/dictionaries";
  import ConfirmationModal from "../ConfirmationModal.svelte";
  import {
    isGrandSlamAdmin,
    isGrandSlamSupervisor,
    isSuperAdmin,
  } from "../../store/user";

  /* Props */
  export let violations: Array<ViolationRecord>;
  export let dictionaries: GetDictionariesResult;
  export let title: string;

  /* Component */
  const dispatch = createEventDispatcher();
  const {
    violationTypes
  } = dictionaries;

  let selectedRow: number = null;
  let openDeleteConfirmation: boolean = false;

  let showAdvancedSearch: boolean = false;
  let searchPlayerName:string = null;
  let searchViolationType: string = '';
  let searchMatchId:string = null;
  let searchMatchDateStr: string;
  let searchMatchDate: string = null;

  const handleSearchChange = () => {
   
      dispatch('filter', {
        searchPlayerName, searchMatchId, searchMatchDate, searchViolationType
      });
    
  }

  const selectViolationRecord = (record: ViolationRecord) => {
    selectedRow = record.id;
    dispatch('select', {
      record,
    });
  };

  const selectViolationToDelete = (e, record: ViolationRecord) => {
    
    record.selectedToDelete = e.target.checked;
    violations = violations;
  };


  $: canNotDelete = (violations && violations.filter(el => { return el.selectedToDelete }).length == 0) || !violations;
  
  $: showDeleteButton = 
    (($isGrandSlamSupervisor || $isSuperAdmin || $isGrandSlamAdmin) && title != "Completed Cases" && title != "Closed cases" );
    
  const createNewCase = () => {
    dispatch('create', {});
  };

  const deleteSelectedCases = async() => {
    var ids = violations.filter(el => { return el.selectedToDelete }).map(el => {return el.id});
    const response = await deleteViolation({
         violationRecordIds: ids
     });
    dispatch("delete", {records: violations.filter(el => { return el.selectedToDelete }),});
    notifications.success(`Selected cases were deleted`);
  }

  
  function handleMatchDateChange(event) {
		const [ selectedDates, dateStr ] = event.detail;
    console.log(selectedDates);
    if(selectedDates.length == 0){
      searchMatchDate = null;
    }
    else{
      searchMatchDate = selectedDates[0];
    }
    handleSearchChange();
	}

  function handleOnKeyDownSearch(event) {
    handleSearchChange();
  }
</script>

<ConfirmationModal
  bind:open={openDeleteConfirmation}
  on:proceed={() => deleteSelectedCases()}
/>

<ColumnHeader>
  <div class="row">
    <div class="col-4">
      {title}
    </div>
    <div class="col-3">
      <Button size="sm"  color="primary" class="float-end" style="margin-left:5px;margin: 0 0 0.5em 0;" on:click={createNewCase}>
        New Case
      </Button>
      {#if showDeleteButton}
        <Button size="sm" disabled={canNotDelete} color="danger" class="float-end" on:click={() => {openDeleteConfirmation = true}}>
          Delete
        </Button>
      {/if}
    </div>
    <div class="col-5">
      <input 
      class="form-control"
      bind:value={searchPlayerName} 
      type="text" 
      placeholder="Search..." 
      on:keyup={handleOnKeyDownSearch}
       />

      <Button size="sm"  color="primary" class="float-end" style="margin-left:5px" on:click={() => {searchMatchDateStr = null, searchMatchDate = null, searchMatchId = null; searchViolationType = ''; searchPlayerName = null; handleSearchChange();}}>
        Clear
      </Button>
      <Button size="sm"  color="primary" class="float-end" style="margin-left:5px" on:click={() => {showAdvancedSearch = !showAdvancedSearch}}>
        Advanced
      </Button>
    </div>
  </div>
  {#if showAdvancedSearch}
    <div class="row" style="margin-top: 10px;">
      <div class="col-3">
        <input bind:value={searchMatchId}  on:keyup={handleOnKeyDownSearch} class="form-control" type="text" placeholder="Match ID" >
      </div>
      <div class="col-3">
        <div class="input-group">
          <Flatpickr
            options={{
              maxDate: new Date(),
              
            }}
            bind:formattedValue={searchMatchDateStr}
            bind:value={searchMatchDateStr}
            on:change={handleMatchDateChange}
            class="form-control form-flatpickr"
            style="background: #fff"
            placeholder="Match Date"
            name="matchDate"
            
          />
          <label for="matchDate" style="height:38px;" class="input-group-text">
            <i class="fas fa-calendar text-dark" />
          </label>

        </div>
      </div>
      <div class="col-6">
        <select
            bind:value={searchViolationType}
            on:change={handleSearchChange}
            placeholder="Violation Type"
            type="select"
            style="font-size: 15px;"
            class="form-select"
            >
            <option value="">--Violation Type--</option>
                  {#if violationTypes}
                    {#each violationTypes as violationType}
                      <option>{violationType}</option>
                    {/each}
                  {/if}
        </select>
      </div>
    </div>
  {/if}
</ColumnHeader>
<ColumnContent>
  <div class="overflow-auto">
    <table class="table table-striped table-hover violation-list">
      <thead>
        <tr>
          
          <th></th>
          
          <th scope="col" class="col-case">Case&nbsp;ID</th>
          <th scope="col" class="text-center"></th>
          <th scope="col" class="col-status">Status</th>
          <th scope="col" class="col-title">Violation</th>
          <th scope="col" class="col-date">Date</th>
        </tr>
      </thead>
      <tbody>
        {#if violations?.length}
          {#each violations as violation}
            <tr class={violation.id === selectedRow ? 'selected' : ''}>
              
              <td>
                {#if ([DRAFT, OPEN].includes(violation.status) && showDeleteButton) }
                  <input type=checkbox checked={violation.selectedToDelete} on:click={(e) => selectViolationToDelete(e, violation)}>
                {/if}
              </td>
              
              <th scope="row" on:click={() => selectViolationRecord(violation)}>
                {violation.recordId}
              </th>
              <td scope="row" class="text-center" on:click={() => selectViolationRecord(violation)}>
                {#if violation.new}
                    <span class="badge label-status new">NEW</span>
                  {/if}
                  {#if violation.returned}
                    <i class="fas fa-exclamation-triangle fa-fw" style="color:#bb2d3b" />
                  {/if}
              </td>
              <td on:click={() => selectViolationRecord(violation)}>
                <span class="badge label-status {getViolationStatusClassName(violation.status)}">{violation.status}</span>
              </td>
              <td on:click={() => selectViolationRecord(violation)}>
                {getViolationShortName(violation)}
              </td>
              <td on:click={() => selectViolationRecord(violation)}>
                <Time
                  timestamp={new Date(violation.matchDate)}
                  format="MMM D"
                />
              </td>
            </tr>
          {/each}
        {:else}
          <tr>
            <td class="text-center" colspan="6">No Violations found</td>
          </tr>
        {/if}
      </tbody>
    </table>
  </div>
</ColumnContent>

<style>
  .table tr {
    cursor: pointer;
  }
  .table tr.selected {
    background-color: #e5e5e5;
  }
  .col-case {
    width: 17%;
  }
  .col-status {
    width: 14%;
  }
  .col-title {
    width: 50%;
  }
  .col-date {
    width: 20%;
  }
  .violation-list {
    font-size: small;
  }
  .text-center{
    text-align: center !important;
  }
  .form-flatpickr{
    background-color: #ffffff !important;
  }
</style>
