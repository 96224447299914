<script lang="ts">
  /* Global dependencies */
  import { createForm } from "svelte-forms-lib";
  import { Form, FormGroup, Input, Card, CardBody, Button } from "sveltestrap";
  import { push, querystring } from "svelte-spa-router";
  import { object, string } from "yup";

  import { saveUserPassword } from "../api/users";

  /* Local dependencies */
  import Layout from "../components/Layout.svelte";
  import FormError from "../components/violations/FormError.svelte";
  import { notifications } from "../helpers/notifications";

  $: isOneTime = new URLSearchParams($querystring)?.has('onetime');

  /* Component */
  let loading = false;
  const {
    form,
    errors,
    state,
    handleChange,
    updateValidateField,
    handleSubmit,
    handleReset,
    touched,
    isValid,
  } = createForm({
    initialValues: {
      password: "",
      passwordConfirmation: "",
    },
    validationSchema: object().shape({
      password: string().required(),
      passwordConfirmation: string().test(
        "passwords-match",
        "Passwords must match",
        function (value) {
          return this.parent.password === value;
        }
      ),
    }),
    onSubmit: (values) => {
      const { password } = values;
      saveUserPassword(password)
        .then((response) => {
          notifications.success("Your new password has been set", 2000);
          handleReset();
          if (isOneTime) {
            push("/");
          }
        })
        .catch((error) => {
          notifications.danger(error);
        })
        .finally(() => {
          loading = false;
        });
    },
  });
</script>

<Layout>
  <section>
    <h1 class="text-center pt-4">Change Password</h1>
  </section>
  {#if isOneTime}
  <div class="alert alert-warning change-password-message">You must change your password, you would not be able to re-login with the one-time password received in your inbox</div>
  {/if}
  <Card class="change-password-card mt-4">
    <CardBody>
      <Form on:submit={handleSubmit}>
        <FormGroup floating label="New Password">
          <Input
            placeholder="Enter a password"
            bind:value={$form.password}
            type="password"
            id="password"
          />
          <FormError error={$errors.password} name="password" />
        </FormGroup>
        <FormGroup floating label="Repeat New Password">
          <Input
            placeholder="Enter a password"
            bind:value={$form.passwordConfirmation}
            type="password"
            id="passwordConfirmation"
          />
          <FormError error={$errors.passwordConfirmation} name="password" />
        </FormGroup>
        <Button
          type="submit"
          id="submit"
          color="danger"
          size="lg"
          block
          disabled={loading}>Submit</Button
        >
      </Form>
    </CardBody>
  </Card>
</Layout>

<style>
  
  :global(.change-password-message) {
    max-width: 600px;
    margin: 0 auto;
    margin-top: 48px;
    margin-bottom: 48px;
  }

  :global(.change-password-card) {
    max-width: 600px;
    margin: 0 auto;
  }
</style>
