import { sendRequest } from '../api.js';
export const addUserToGrandSlam = async (userId, grandSlamId) => {
    const payload = {
        userId,
        grandSlamId,
    };
    const response = await sendRequest('/api/users/addUserToGs', 'PUT', JSON.stringify(payload));
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject(response);
};
export const getGrandSlamUsers = async () => {
    const response = await sendRequest('/api/users/getGsUsers', 'GET');
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject('Failed to load Grand Slam users list');
};
export const getUsers = async () => {
    const response = await sendRequest('/api/users/getUsers', 'GET');
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject('Failed to load users list');
};
export const deleteUser = async (id) => {
    const payload = {
        id,
    };
    const response = await sendRequest('/api/users/deleteUser', 'DELETE', JSON.stringify(payload));
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject(response);
};
export const removeUserFromGrandSlam = async (userId, grandSlamId) => {
    const payload = {
        userId,
        grandSlamId,
    };
    const response = await sendRequest('/api/users/removeUserFromGs', 'DELETE', JSON.stringify(payload));
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject(response);
};
export const resetTempPassword = async (email) => {
    const response = await sendRequest(`/api/notifications/notifyOneTimePasswordUser?email=${email}`, 'GET');
    if (response.ok) {
        return true;
    }
    return response;
};
export const saveUser = async (data) => {
    const response = await sendRequest('/api/users/saveUser', 'POST', JSON.stringify(data));
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject(response);
};
export const saveUserPassword = async (newPassword) => {
    const response = await sendRequest('/api/users/saveUserPassword', 'POST', JSON.stringify({ newPassword }));
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject(response);
};
