export const getViolationStatusClassName = (status) => {
  const DEFAULT = "";
  const options = {
    OPEN: "status-open",
    COMPLETE: "status-complete",
    CLOSED: "status-closed",
  };
  return options[status] || DEFAULT;
};

export const getViolationShortName = (violation) => {
  const pieces = [
    `${violation.drawGender}'s`, // Women's
    violation.drawType.substring(0, 1) // D
  ];
  if (violation.drawLevel === 'Qualifying') {
    pieces.push('Q');
  }
  pieces.push(violation.round);
  const players = getViolationPlayers(violation);
  if (players) {
    pieces.push(players);
  }
  return pieces.join(' ');
};

export const getViolationPlayers = (violation) => {
  if (!violation.offendingPlayers?.length) {
    return '';
  }
  const [p1, p2] = violation.offendingPlayers;
  // const [p2] = violation.opponentPlayers;
  const players = [`${p1.firstName.substring(0, 1)}. ${p1.lastName}`];
  if (p2) {
    players.push(`${p2.firstName.substring(0, 1)}. ${p2.lastName}`);
  }
  return players.join(' / ');
}