import { downloadRequest, sendRequest } from '../api.js';
const getUrlForDownload = async (fileName) => {
    const response = await sendRequest(`/api/Attachments/GetUrlForFileDownload?fileName=${fileName}`, 'GET', null);
    if (response.ok) {
        return (await response.text());
    }
    return Promise.reject('Something went wrong');
};
const getUrlForUpload = async (fileName, contentType, groupFile) => {
    const response = await sendRequest(`/api/Attachments/GetClientUploadUrl?fileName=${fileName}&contentType=${contentType}&groupFile=${groupFile}`, 'GET', null);
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject('Something went wrong');
};
export const upLoadFile = async (selectedFile, groupFile) => {
    const upLoadFileResult = await getUrlForUpload(selectedFile.name, selectedFile.type, groupFile);
    const awsUploadResult = await fetch(upLoadFileResult.uploadUrl, {
        method: 'PUT',
        body: selectedFile,
    });
    return { upLoadFileResult, awsUploadResult };
};
export const downloadAttachment = async (id, fileName) => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);
    downloadRequest(`/api/attachments/downloadFile?id=${id}`).then((blobby) => {
        let objectUrl = window.URL.createObjectURL(blobby);
        anchor.href = objectUrl;
        anchor.download = `${fileName}`;
        anchor.click();
        window.URL.revokeObjectURL(objectUrl);
        console.log('then 2');
    });
};
export const requestAttachment = async (id) => {
    return downloadRequest(`/api/attachments/downloadFile?id=${id}`);
};
export const downloadFile = async (key) => {
    getUrlForDownload(key).then((result) => {
        fetch(result.url, {
            method: 'GET',
        }).then(async (url) => {
            const b = await url.blob();
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", key);
            a.click();
        });
    });
};
export const clientUploadCompleted = async (attachmentId) => {
    const response = await sendRequest('/api/attachments/clientUploadCompleted', 'POST', JSON.stringify({ attachmentId }));
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject('Something went wrong');
};
