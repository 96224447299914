<script lang="ts">
  /* Global dependencies */
  import {
    Navbar,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from "sveltestrap";
  import { location } from "svelte-spa-router";
  import { onDestroy } from "svelte";

  const LOGO_PER_SLAM = {
    "Australian Open": "gs-logo-aus.png",
    "Roland Garros": "gs-logo-rol.png",
    "US Open": "gs-logo-uso.png",
    "Wimbledon Championships": "gs-logo-wim.png",
  }

  /* GSFT dependencies */
  import { activeGrandSlam as activeGrandSlamStore } from "../store/active-grand-slam";
  import {
    user,
    isGrandSlamAdmin,
    isSuperAdmin,
  } from "../store/user";
  import type { GrandSlam } from "../types/grand-slams";

  /* Component */
  let isOpen = false;
  let activeGrandSlam = null;

  const unsubscribe = activeGrandSlamStore.subscribe((gs: GrandSlam) => {
    if (gs) {
      activeGrandSlam = gs;
    }
  });
  onDestroy(unsubscribe);

  const getSlamLogo = (slamName) => {
    // slam name is "US Open 2022"
    const slamNameWithoutYear = slamName?.toLowerCase() === "us open" ? slamName : slamName?.substring(0, slamName?.length-5);
    return LOGO_PER_SLAM[slamNameWithoutYear] || "usta-logo-white.png";
  }
</script>

<Navbar color="light" light expand="md">
  <NavbarBrand href="./">
    <img src="./images/gsft-board-resized.png" height="60" alt="Grand Slam Board" />
  </NavbarBrand>
  <button
    class="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
    on:click={() => (isOpen = !isOpen)}
  >
    <span class="navbar-toggler-icon" />
  </button>
  <div
    class="collapse navbar-collapse {isOpen ? 'show' : ''}"
    id="navbarSupportedContent"
  >
    {#if $isGrandSlamAdmin}
      <Nav>
        <NavItem>
          <NavLink href="#/settings" active={$location === "/settings"}
            >Grand Slam Settings</NavLink
          >
        </NavItem>
        <NavItem>
          <NavLink href="#/courts" active={$location === "/courts"}
            >Courts</NavLink
          >
        </NavItem>
        <NavItem>
          <NavLink href="#/users" active={$location === "/users"}
            >User Administration</NavLink
          >
        </NavItem>
      </Nav>
    {/if}
    {#if $isSuperAdmin}
      <Nav>
        <NavItem>
          <NavLink href="#/violations/my" active={$location.startsWith("/violations")}
            >Violations</NavLink
          >
        </NavItem>
        <NavItem>
          <NavLink href="#/settings" active={$location === "/settings"}
            >Grand Slam Settings</NavLink
          >
        </NavItem>
        <NavItem>
          <NavLink href="#/courts" active={$location === "/courts"}
            >Courts</NavLink
          >
        </NavItem>
        <NavItem>
          <NavLink href="#/users" active={$location === "/users"}
            >User Administration</NavLink
          >
        </NavItem>
      </Nav>
    {/if}
    <Nav class="ms-auto" navbar>
      <Dropdown inNavbar>
        <DropdownToggle nav caret>
          {$user?.email}
          <i class="fas fa-cog fa-fw ml-3" />
        </DropdownToggle>
        <DropdownMenu right>
          <a class="dropdown-item" href="#/password"
            ><i class="fas fa-key" /> Change Password</a
          >
          <DropdownItem divider />
          <a class="dropdown-item" href="#/logout"
            ><i class="fas fa-sign-out-alt" /> Logout</a
          >
        </DropdownMenu>
      </Dropdown>
    </Nav>
  </div>
</Navbar>

<style>
  .dropdown-item:hover {
    text-decoration: none;
  }
  .dropdown-item .fas {
    margin-right: 1rem;
  }
</style>
