import { sendRequest } from '../api.js';
// this api endpoint is not existed
export const saveAttachment = async (data) => {
    const response = await sendRequest(`/api/Attachments/Save`, 'POST', JSON.stringify(data));
    if (response.ok) {
        return await response.json();
    }
    return Promise.reject('Something went wrong');
};
export const saveLink = async (data) => {
    const response = await sendRequest(`/api/attachments/saveLInk`, 'POST', JSON.stringify(data));
    if (response.ok) {
        return await response.json();
    }
    return Promise.reject('Something went wrong');
};
