<script lang="ts">
  /* Global dependencies */
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";

  /* GSFT dependencies */
  import { user, isChairUmpire, isGrandSlamSupervisor, isGrandSlamAdmin, isSuperAdmin } from "../store/user";
  import Layout from "../components/Layout.svelte";

  /* Component */
  onMount(() => {
    if ($isChairUmpire || $isGrandSlamSupervisor) push("/violations/my");
    if ($isGrandSlamAdmin) push("/settings");
    if ($isSuperAdmin) push("/users");
  });
</script>

<Layout>
  <section>
    <h1 class="text-center pt-5">Hello, {$user.name}!</h1>
  </section>
</Layout>
