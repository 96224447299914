<script>
  /* Props */
  export let loading;
</script>

{#if loading}
  <div class="wrapper-loader">
    <div class="spinner-border text-primary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
{/if}
