<script type="ts">
  /* Global dependencies */
  import { afterUpdate, onDestroy } from "svelte";
  import Time from "svelte-time";
  import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Input,
  } from "sveltestrap";

  /* GSFT dependencies */
  import { user } from "../../store/user";
  import {
    getMessagesViolation,
    createMessageViolation,
  } from "../../api/violations";
  import type { Message } from "../../types/violations";

  /* Props */
  export let open = false;
  export let violationRecordId = null;
  let message = "";
  let messages: Array<Message> = [];
  let polling = null;

  /* Component */
  const toggle = () => {
    open = !open;
    if (!open) {
      clearInterval(polling);
    }
  };

  $: if (open) {
    getMessages();
    scrollMessages();
    polling = setInterval(function () {
      getMessages();
    }, 0.5 * 60 * 1000);
  }

  onDestroy(() => {
    clearInterval(polling);
  });

  afterUpdate(() => {
    scrollMessages();
  });

  const getMessages = () => {
    if (violationRecordId) {
      getMessagesViolation({ violationRecordId })
        .then((messagesResult: Array<Message>) => {
          messages = messagesResult;
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  const sendMessage = () => {
    if (message) {
      const newMessage = {
        violationRecordId: violationRecordId,
        userId: $user.id,
        textMessage: message,
      };

      createMessageViolation(newMessage)
        .then((messagesResult: Array<Message>) => {
          messages = messagesResult;
          message = "";
        })
        .catch((error) => {
          alert(error);
        });
    }
  };

  const scrollMessages = () => {
    let divMessages = document.getElementById("div-messages");
    if (divMessages) {
      divMessages.scrollTop = divMessages.scrollHeight;
    }
  };
</script>

<div>
  <Modal isOpen={open} {toggle} size="lg" backdrop="static">
    <ModalHeader {toggle}>Messages</ModalHeader>
    <ModalBody class="message-body">
      {#if messages.length}
      <div>
        <div class="row header">
          <div class="col-2 col-md-2">User</div>
          <div class="col-2 col-md-2">Time</div>
          <div class="col-8 col-md-8">Note</div>
        </div>
        <div id="div-messages" class="global mb-3">
          {#each messages as item, index}
            <div class="row message">
              <div class="col-2 col-md-2 message-details">{item.authorFullName}</div>
              <div class="col-2 col-md-2 message-details">
                <Time timestamp={item.createdDate} format="MM/DD/YY hh:mma" />
              </div>
              <div class="col-8 col-md-8">{item.textMessage}</div>
            </div>
          {/each}
        </div>
      </div>
      {:else}
      <p class="empty-list-message">There are no messages for this violation record yet</p>
      {/if}
      <div class="col-md-12">
        <h5>Add your message</h5>
        <Input
          type="textarea"
          name="text"
          maxlength={300}
          bind:value={message}
        />
      </div>
    </ModalBody>
    <ModalFooter>
      <Button color="primary" on:click={sendMessage} disabled={!message}
        >Send Message</Button
      >
    </ModalFooter>
  </Modal>
</div>

<style type="text/css">
  .empty-list-message {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .header {
    margin-bottom: 0.5rem !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    font-weight: bold;
  }
  .global {
    height: 250px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .message {
    margin-bottom: 5px;
    padding: 2px;
    height: auto;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: white;
  }
  .message-details {
    font-size: smaller;
  }
</style>
