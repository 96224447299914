<script lang="ts">
  /* Global dependencies */
  import { createEventDispatcher, onDestroy } from "svelte";

  /* GSFT dependencies */
  import {
    Modal,
    ModalBody,
    ModalHeader,
  } from 'sveltestrap';
  import type { GrandSlam } from "../../types/grand-slams";
  import type { GsUser } from '../../types/users';
  import { addUserToGrandSlam, removeUserFromGrandSlam } from "../../api/users";
  import { activeGrandSlam as activeGrandSlamStore } from "../../store/active-grand-slam";
  import { notifications } from "../../helpers/notifications";

  export let open = false;
  export let action: string;
  export let user: GsUser;
  let activeGrandSlam: GrandSlam;

  let saving = false;
  const dispatch = createEventDispatcher();

  const toggle = () => {
    open = !open;
  };

  const unsubscribe = activeGrandSlamStore.subscribe((gs: GrandSlam) => {
    if (gs) {
      activeGrandSlam = gs;
    }
  });

  const toggleUserToGrandSlamRelation = async (userId: number, action: string)=> {
    saving = true
    try {
      let result;
      if (action === "ADD") {
        result = await addUserToGrandSlam(userId, activeGrandSlam.id);
      } else if (action === "REMOVE") {
        result = await removeUserFromGrandSlam(userId, activeGrandSlam.id);
      }
      if (result === true) {
        dispatch('reloadUsers');
        toggle();
      }
    } catch (err) {
      notifications.danger(
        `Error toggling user / grand slam relation: ${err?.detail == undefined ? '' : err.detail}`
      );
    } finally {
      saving = false;
    }
  }

  const discard = ()=> {
    toggle();
  }

  onDestroy(unsubscribe);

</script>

<Modal isOpen={open} size="md" {toggle} id="toggleUserToCurrentGrandSlamRelationModal">
  <ModalHeader {toggle}>
    {`${action === "ADD"
      ? `Add "${user.firstName} ${user.lastName}" to`
      : `Remove "${user.firstName} ${user.lastName}" from`} currently active Grand Slam?`}
  </ModalHeader>
  <ModalBody>
    <div class="row">
      <div class="col text-center">
        <button type="button" class="btn btn-success" disabled={saving} on:click={() => toggleUserToGrandSlamRelation(user.id, action)}>
          {`${action === "ADD" ? "Add user to" : "Remove user from"} the Slam`}
        </button>
        <button type="button" class="btn btn-light" on:click={discard} disabled={saving}>
          {`Do not ${action === "ADD" ? "add" : "remove user"}`}
        </button>
      </div>
    </div>
  </ModalBody>
</Modal>

