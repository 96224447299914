<script type="ts">
  /* Global dependencies */
  import { createEventDispatcher } from "svelte";
  import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
  } from "sveltestrap";

  /* GSFT dependencies */

  /* Props */
  export let open;
  export let uploadResults;

  /* Component */
  const dispatch = createEventDispatcher();
  const toggle = () => {
    open = !open;
  };
  const cancel = () => {
    dispatch("cancel");
    toggle();
  };
</script>

<Modal isOpen={open} size="lg" {toggle}>
  <ModalHeader {toggle}>Import Results</ModalHeader>
  <ModalBody class="fs-5">
    {#if uploadResults && uploadResults.length > 0}
      <ul class="list-group">
        {#each uploadResults as result}
          <li
            class="list-group-item {result.actionResult === 'Processed'
              ? 'list-group-item-success'
              : 'list-group-item-danger'}"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{result.firstName} {result.lastName}</h5>
              <small>{result.itfId}</small>
            </div>
            <p class="mb-1">{result.actionResult}</p>
          </li>
        {/each}
      </ul>
      <div class="row mt-2">
        <div class="col text-end">
          Total Processed: <span class="badge bg-success">
            {uploadResults.filter((el) => el.actionResult === "Processed")
              .length}
          </span>
        </div>
        <div class="col text-start">
          Total Failed: <span class="badge bg-danger"
            >{uploadResults.filter((el) => el.actionResult !== "Processed")
              .length}</span
          >
        </div>
      </div>
    {/if}
  </ModalBody>
  <ModalFooter>
    <Button on:click={cancel} class="me-auto">Close</Button>
  </ModalFooter>
</Modal>
