<script type="ts">
  /* Global dependencies */
  import { onMount, onDestroy } from 'svelte';
  import { push } from 'svelte-spa-router';

  /* GSFT dependencies */
  import { token } from '../store/token.js';
  import { user } from '../store/user.js';
  import Spinner from '../components/Spinner.svelte';
  import Toast from '../components/Toast.svelte';

  /* Component */
  onMount(async () => {
    $token = null;
    $user = null;
    push('/login');
  });
</script>

<main class="form-signin">
  <Spinner loading={true} />
</main>
<Toast />
