<script lang="ts">
  /* GSFT dependencies */
  import {
    Modal,
    ModalBody,
    ModalHeader,
  } from 'sveltestrap';
  import type { GsUser } from '../../types/users';
  import { resetTempPassword } from "../../api/users";
  import { notifications } from "../../helpers/notifications";

  export let open = false;
  export let user: GsUser;

  let saving = false;

  const toggle = () => {
    open = !open;
  };

  const resetUserTempPassword = async (email: string)=> {
    saving = true
    try {
      const result = await resetTempPassword(email);
      if (result) {
        notifications.success(
          `Invitation email resent successfully`
        );
        toggle();
      } else {
        notifications.danger(
          `Error resending invitation email`
        );
      }
    } catch (err) {
      notifications.danger(
        `Error resending invitation email: ${err?.detail == undefined ? '' : err.detail}`
      );
    } finally {
      saving = false;
    }
  }

  const discard = ()=> {
    toggle();
  }

</script>

<Modal isOpen={open} size="md" {toggle} id="toggleUserToCurrentGrandSlamRelationModal">
  <ModalHeader {toggle}>
    {`Resend "${user.firstName} ${user.lastName}" invitation email?`}
  </ModalHeader>
  <ModalBody>
    <div class="row">
      <div class="col text-center">
        <button type="button" class="btn btn-success" disabled={saving} on:click={() => resetUserTempPassword(user.email)}>
          Resend invitation email
        </button>
        <button type="button" class="btn btn-light" on:click={discard} disabled={saving}>
          Do not resend
        </button>
      </div>
    </div>
  </ModalBody>
</Modal>

