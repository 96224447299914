import { writable, derived } from 'svelte/store';
export const SUPER_ADMIN = 'SysAdmin';
export const GRAND_SLAM_ADMIN = 'Grand Slam Admin';
export const GRAND_SLAM_SUPERVISOR = 'Grand Slam Supervisor';
export const CHAIR_UMPIRE = 'Chair Umpire';
const storedUser = localStorage.getItem('user');
export const user = writable(storedUser ? JSON.parse(storedUser) : null);
user.subscribe((value) => {
    localStorage.setItem('user', value ? JSON.stringify(value) : null);
});
export const isSuperAdmin = derived(user, ($user) => $user && $user.role && $user.role === SUPER_ADMIN);
export const isGrandSlamAdmin = derived(user, ($user) => $user && $user.role && $user.role === GRAND_SLAM_ADMIN);
export const isGrandSlamSupervisor = derived(user, ($user) => $user && $user.role && $user.role === GRAND_SLAM_SUPERVISOR);
export const isChairUmpire = derived(user, ($user) => $user && $user.role && $user.role === CHAIR_UMPIRE);
