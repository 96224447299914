<script type="ts">
  /* Global dependencies */
  import { createEventDispatcher } from "svelte";

  /* GSFT dependencies */
  import type { ChairUmpire } from "../../types/violations";
  import ConfirmationModal from "../ConfirmationModal.svelte";
  import Spinner from "../Spinner.svelte";
  import ChairUmpireModal from "./ChairUmpireModal.svelte";
  import {
    addChairUmpiresToGs,
    toggleActivateChairUmpire,
    deleteChairUmpire,
    exportCandiateList,
    importCandiateList,
    removeChairUmpiresToGs,
    saveChairUmpire,
  } from "../../api/chair-umpires";
  import { notifications } from "../../helpers/notifications";
  import type { GrandSlam } from "../../types/grand-slams";
  import UploadResultsModal from "./UploadResultsModal.svelte";

  /* Props */
  export let activeGrandSlam: GrandSlam;
  export let genders: Array<string>;
  export let nationalities: Array<string>;
  export let badges: Array<string>;
  export let candidatesChairUmpires: Array<ChairUmpire>;
  export let gsChairUmpires: Array<ChairUmpire>;

  $: candidates = candidatesChairUmpires.filter(
    (el: ChairUmpire) => gsChairUmpires.findIndex((f) => f.id === el.id) === -1
  );

  /* Component */
  const dispatch = createEventDispatcher();
  let processing = false;
  let selectedChairUmpire: ChairUmpire = null;
  let showChairUmpireModal = false;
  let openDeleteConfirmation = false;
  let showUploadResultsModal = false;
  let toggleActivateConfirmation = false;
  let toggleActivateDoDeactivate = true;
  let uploadResults: Array<ChairUmpire> = [];
  let fileinput;

  const moveCandidates = () => {
    processing = true;
    const ids = candidates.filter((el) => el.selected).map((el) => el.id);
    addChairUmpiresToGs(ids)
      .then((resGs: ChairUmpire) => {
        notifications.success("Candidate(s) was moved");
      })
      .catch((error) => {
        let message = "Something went wrong";
        if (error) {
          message = `${message}. ${error}`;
        }
        notifications.danger(message);
      })
      .finally(() => {
        processing = false;
        dispatch("refreshUmpires");
      });
  };

  const moveUmpires = () => {
    processing = true;
    const ids = gsChairUmpires.filter((el) => el.selected).map((el) => el.id);
    removeChairUmpiresToGs(ids)
      .then((resGs: ChairUmpire) => {
        notifications.success("Chair Umpire(s) was moved");
      })
      .catch((error) => {
        let message = "Something went wrong";
        if (
          error.toString() ===
          "Chair Umpire is associated with Violation Record."
        ) {
          message =
            "This umpire can not be removed from the slam as they have Violations assigned to them.";
        } else if (error) {
          message = `${message}: ${error}`;
        }
        notifications.danger(message);
      })
      .finally(() => {
        processing = false;
        dispatch("refreshUmpires");
      });
  };

  const clearChairUmpires = () => {
    processing = true;
    const ids = gsChairUmpires.map((el) => el.id);
    removeChairUmpiresToGs(ids)
      .then((resGs: ChairUmpire) => {
        notifications.success("Chair Umpire(s) was moved");
      })
      .catch((error) => {
        let message = "Something went wrong";
        if (error) {
          message = `${message}. ${error}`;
        }
        notifications.danger(message);
      })
      .finally(() => {
        processing = false;
        dispatch("refreshUmpires");
      });
  };

  const deleteUmpire = () => {
    processing = true;
    deleteChairUmpire(selectedChairUmpire.itfId)
      .then((deleted: boolean) => {
        if (deleted) {
          notifications.success("Chair Umpire was deleted");
        } else {
          notifications.danger("Chair Umpire wasn't deleted");
        }
      })
      .catch((error) => {
        let message = "Something went wrong";
        if (error) {
          message = `${message}. ${error}`;
        }
        notifications.danger(message);
      })
      .finally(() => {
        processing = false;
        selectedChairUmpire = null;
        dispatch("refreshUmpires");
      });
  };

  const toggleActivateUmpire = (doDeactivate: boolean) => {
    processing = true;
    toggleActivateChairUmpire(selectedChairUmpire.itfId, doDeactivate)
      .then((success: boolean) => {
        const action = doDeactivate ? "deactivated" : "activated";
        if (success) {
          notifications.success(`Chair Umpire was ${action}`);
        } else {
          notifications.danger(`Chair Umpire wasn't ${action}`);
        }
      })
      .catch((error) => {
        let message = "Something went wrong";
        if (error) {
          message = `${message}. ${error}`;
        }
        notifications.danger(message);
      })
      .finally(() => {
        processing = false;
        selectedChairUmpire = null;
        dispatch("refreshUmpires");
      });
  };

  const saveUmpire = (event: CustomEvent) => {
    const { chairUmpire } = event.detail;
    processing = true;
    if (chairUmpire) {
      saveChairUmpire(chairUmpire)
        .then((resGs: ChairUmpire) => {
          notifications.success("Chair Umpire was saved");
          showChairUmpireModal = false;
          dispatch("refreshUmpires");
        })
        .catch((error) => {
          let message = "Something went wrong";
          if (error) {
            message = `${message}. ${error}`;
          }
          notifications.danger(message);
        })
        .finally(() => {
          processing = false;
          dispatch("refreshUmpires");
        });
    }
  };

  const downloadCandidatesList = () => {
    processing = true;
    const fileName = `Candidates_${activeGrandSlam.name}_${new Date()
      .toJSON()
      .slice(0, 10)}`;
    exportCandiateList(fileName).finally(() => {
      processing = false;
      dispatch("refreshUmpires");
    });
  };

  const uploadCandidates = (e) => {
    const formData = new FormData();
    formData.append("FILE", e.target.files[0]);
    processing = true;
    importCandiateList(formData)
      .then((res: Array<ChairUmpire>) => {
        uploadResults = res;
        showUploadResultsModal = true;
        notifications.success("File was imported");
        dispatch("refreshUmpires");
      })
      .catch((error) => {
        let message = "Something went wrong";
        if (error) {
          message = `${message}. ${error}`;
        }
        notifications.danger(message);
      })
      .finally(() => {
        processing = false;
        dispatch("refreshUmpires");
      });
  };
</script>

{#if showChairUmpireModal}
<ChairUmpireModal
  chairUmpire={selectedChairUmpire}
  {badges}
  {nationalities}
  {genders}
  bind:open={showChairUmpireModal}
  on:save={saveUmpire}
/>
{/if}
<ConfirmationModal
  bind:open={openDeleteConfirmation}
  on:proceed={deleteUmpire}
  on:cancel={() => (selectedChairUmpire = null)}
/>
<ConfirmationModal
  bind:open={toggleActivateConfirmation}
  on:proceed={() => toggleActivateUmpire(toggleActivateDoDeactivate)}
  on:cancel={() => (selectedChairUmpire = null)}
/>

{#if showUploadResultsModal}
  <UploadResultsModal
    {uploadResults}
    bind:open={showUploadResultsModal}
    on:cancel={() => console.log("test")}
  />
{/if}
<form class="position-relative" on:submit|preventDefault>
  <Spinner loading={processing} />
  <fieldset>
    <div class="row">
      <div class="col">
        <h5>List of Candidate Chair Umpires</h5>
        <ul class="list-group list-group-flush">
          {#each candidates as candidate}
            <li class="d-flex">
              <label
                class="list-group-item flex-grow-1 {!candidate.active
                  ? 'disabled'
                  : ''}"
                disabled={!candidate.active}
              >
                <input
                  class="form-check-input me-1"
                  type="checkbox"
                  value=""
                  aria-label="..."
                  bind:checked={candidate.selected}
                />
                {candidate.id}
                {candidate.firstName}
                {candidate.lastName}
                {#if !candidate.active}
                  (deactivated)
                {/if}
              </label>
              {#if candidate.active}
                <button
                  title="Dectivate Umpire"
                  class="btn btn-light btn-sm mb-0 me-1 float-end"
                  on:click={() => {
                    selectedChairUmpire = candidate;
                    toggleActivateDoDeactivate = true;
                    toggleActivateConfirmation = true;
                  }}><i class="fas fa-ban" /></button
                >
              {/if}
              {#if !candidate.active}
                <button
                  title="Activate Umpire"
                  class="btn btn-light btn-sm mb-0 me-1 float-end"
                  on:click={() => {
                    selectedChairUmpire = candidate;
                    toggleActivateDoDeactivate = false;
                    toggleActivateConfirmation = true;
                  }}><i class="fas fa-undo" /></button
                >
              {/if}
              <button
                class="btn btn-light btn-sm mb-0 me-1 float-end"
                on:click={() => {
                  selectedChairUmpire = candidate;
                  openDeleteConfirmation = true;
                }}><i class="fas fa-trash" /></button
              >
              <button
                class="btn btn-light btn-sm mb-0 float-end"
                on:click={() => {
                  selectedChairUmpire = candidate;
                  showChairUmpireModal = true;
                }}><i class="fas fa-pencil-alt" /></button
              >
            </li>
          {/each}
          {#if candidatesChairUmpires.length === 0}
            <li class="list-group-item text-center">No Candidates</li>
          {/if}
        </ul>
        <button
          type="button"
          class="btn btn-success mt-3"
          on:click={() => fileinput.click()}
          ><i class="fas fa-upload" /> Bulk Upload</button
        >
        <input
          style="display:none"
          type="file"
          bind:this={fileinput}
          on:change={uploadCandidates}
        />
        <button class="btn btn-light mt-3" on:click={downloadCandidatesList}
          ><i class="fas fa-download" /> Download</button
        >
        <button
          type="button"
          class="btn btn-primary mt-3 float-end ms-auto"
          on:click={() => {
            selectedChairUmpire = null;
            showChairUmpireModal = true;
          }}><i class="fas fa-plus" /> Add Candidate</button
        >
      </div>
      <div class="col-1 align-self-center">
        <div class="text-center">
          <button
            type="button"
            class="btn btn-dark mt-3"
            on:click={moveCandidates}
            disabled={!candidates.some((el) => el.selected)}
          >
            <i class="fas fa-angle-right" />
          </button>
          <br />
          <button
            type="button"
            class="btn btn-dark mt-3"
            on:click={moveUmpires}
            disabled={!gsChairUmpires.some((el) => el.selected)}
          >
            <i class="fas fa-angle-left" />
          </button>
        </div>
      </div>
      <div class="col">
        <h5>Chair Umpires in Active Grand Slam</h5>
        <ul class="list-group list-group-flush">
          {#each gsChairUmpires as umpire}
            <li class="d-flex">
              <label
                class="list-group-item flex-grow-1 {!umpire.active
                  ? 'disabled'
                  : ''}"
                disabled={!umpire.active}
              >
                <input
                  class="form-check-input me-1"
                  type="checkbox"
                  value=""
                  aria-label="..."
                  bind:checked={umpire.selected}
                />
                {umpire.firstName}
                {umpire.lastName}
                {#if !umpire.active}
                  (deactivated)
                {/if}
              </label>
              {#if umpire.active}
                <button
                  title="Dectivate Umpire"
                  class="btn btn-light btn-sm mb-0 me-1 float-end"
                  on:click={() => {
                    selectedChairUmpire = umpire;
                    toggleActivateDoDeactivate = true;
                    toggleActivateConfirmation = true;
                  }}><i class="fas fa-ban" /></button
                >
              {/if}
              {#if !umpire.active}
                <button
                  title="Activate Umpire"
                  class="btn btn-light btn-sm mb-0 me-1 float-end"
                  on:click={() => {
                    selectedChairUmpire = umpire;
                    toggleActivateDoDeactivate = false;
                    toggleActivateConfirmation = true;
                  }}><i class="fas fa-undo" /></button
                >
              {/if}
              <button
                class="btn btn-light btn-sm mb-0 me-1 float-end"
                on:click={() => {
                  selectedChairUmpire = umpire;
                  openDeleteConfirmation = true;
                }}><i class="fas fa-trash" /></button
              >
              <button
                class="btn btn-light btn-sm mb-0 float-end"
                on:click={() => {
                  selectedChairUmpire = umpire;
                  showChairUmpireModal = true;
                }}><i class="fas fa-pencil-alt" /></button
              >
            </li>
          {/each}
          {#if gsChairUmpires.length === 0}
            <li class="list-group-item text-center">
              No umpires in current slam
            </li>
          {/if}
        </ul>
        <button
          type="button"
          class="btn btn-dark mt-3 float-end ms-auto"
          on:click={clearChairUmpires}
          disabled={gsChairUmpires.length === 0}
          ><i class="fas fa-broom" /> Clear</button
        >
      </div>
    </div>
  </fieldset>
</form>

<style>
  .list-group {
    height: 300px;
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 4px;
    overflow: auto;
  }
  .list-group .btn {
    padding-top: 2px;
    padding-bottom: 2px;
  }
</style>
