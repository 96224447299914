import { sendRequest, downloadRequest } from '../api.js';
export const getViolations = async () => {
    const response = await sendRequest(`/api/violationRecords/getGsViolationRecords`, 'GET');
    if (response.ok) {
        const records = await response.json();
        return records.sort((a, b) => b.id - a.id);
    }
    return Promise.reject('Something went wrong');
};
export const createDraftViolation = async (data) => {
    const response = await sendRequest(`/api/violationRecords/draft`, 'POST', JSON.stringify(data));
    if (response.ok) {
        return await response.json();
    }
    if (response && response.errors) {
        return Promise.reject(response.errors);
    }
    else if (response && response.detail) {
        return Promise.reject([response.detail]);
    }
    return Promise.reject('Something went wrong');
};
export const updateViolation = async (record) => {
    let status = record.status.toLowerCase();
    const response = await sendRequest(`/api/violationRecords/${status}`, 'POST', JSON.stringify(record));
    if (response.ok) {
        return await response.json();
    }
    if (response && response.errors) {
        return Promise.reject(response.errors);
    }
    else if (response && response.detail) {
        return Promise.reject([response.detail]);
    }
    return Promise.reject('Something went wrong');
};
export const moveToStatus = async (data) => {
    const response = await sendRequest('/api/violationRecords/moveToStatus', 'POST', JSON.stringify(data));
    if (response.ok) {
        return await response.json();
    }
    if (response && response.errors) {
        return Promise.reject(response.errors);
    }
    else if (response && response.detail) {
        return Promise.reject([response.detail]);
    }
    return Promise.reject('Something went wrong');
};
export const deleteViolation = async (data) => {
    const response = await sendRequest('/api/violationRecords/delete', 'DELETE', JSON.stringify(data));
    if (response.ok) {
        return await true;
    }
    return Promise.reject('Something went wrong');
};
export const getMessagesViolation = async (data) => {
    const response = await sendRequest(`/api/violationRecords/GetMessagesViolationRecord/?violationRecordId=${data.violationRecordId}`, 'GET');
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject('Something went wrong');
};
export const createMessageViolation = async (data) => {
    const response = await sendRequest(`/api/violationRecords/CreateMessageViolation`, 'POST', JSON.stringify(data));
    if (response.ok) {
        return await response.json();
    }
    return Promise.reject('Something went wrong');
};
export const getRecordPdfReport = async (recordId, playerInvolvedCode) => {
    return downloadRequest(`/api/violationRecords/getRecordPdfReport?violationRecordId=${recordId}&PlayerInvolvedCode=${playerInvolvedCode}`).then((blobby) => {
        return window.URL.createObjectURL(blobby);
    });
};
