<script lang="ts">
  /* GSFT dependencies */
  import { createEventDispatcher } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import {
    Form,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    FormGroup,
    Label,
  } from "sveltestrap";
  import { object, string } from "yup";

  import type { GsUser } from "../../types/users";
  import { saveUser } from "../../api/users";
  import { dictionaries as dictionariesStore } from "../../store/dictionaries";
  import type { GetDictionariesResult } from "../../types/dictionaries";
  import { notifications } from "../../helpers/notifications";
  import {
    isGrandSlamAdmin,
    isSuperAdmin,
  } from "../../store/user";

  import FormError from "../violations/FormError.svelte";

  /* Props */
  export let user: GsUser;
  export let open;
  export let action: string;
  let isSavingInProgress = false;
  let dictionaries: GetDictionariesResult;
  const dispatch = createEventDispatcher();

  const ROLE_UMPIRE = "Chair Umpire";
  const ROLE_SUPER_ADMIN = "SysAdmin";

  dictionariesStore.subscribe((dictionariesResult: GetDictionariesResult) => {
    if (dictionariesResult) {
      dictionaries = dictionariesResult;
    }
  });

  const toggle = () => {
    const nextOpen = !open;
    if (!nextOpen) {
      dispatch("close");
    }
    open = !open;
  };

  const manageSaveCompleted = (userToSave) => {
    dispatch("reloadUsers");
    user = null;
    toggle();
  };

  const addOrEditGsUser = async (userToSave: GsUser) => {
    isSavingInProgress = true;
    try {
      const userResult = await saveUser(userToSave);
      manageSaveCompleted(userResult);
    } catch (err) {
      notifications.danger(
        `Error saving user. ${err?.detail == undefined ? "" : err.detail}`
      );
    } finally {
      isSavingInProgress = false;
    }
  };

  const discard = (handleReset) => {
    toggle();
    handleReset();
  };

  const {
    form,
    errors,
    state,
    handleChange,
    updateValidateField,
    handleSubmit,
    handleReset,
    touched,
    isValid,
    isModified,
  } = createForm({
    initialValues: {
      id: user ? user.id : 0,
      firstName: user ? user.firstName : "",
      lastName: user ? user.lastName : "",
      email: user ? user.email : "",
      role: user ? user.role : "",
      active: true,
    },
    validationSchema: object().shape({
      id: string().required(),
      firstName: string().required().min(2, "Must be at least 2 symbols"),
      lastName: string().required().min(2, "Must be at least 2 symbols"),
      email: string().required().email(),
      role: string().required(),
    }),
    onSubmit: async (values) => {
      const newValues = { ...values, belongsToCurrentGrandSlam: false };
      await addOrEditGsUser(newValues);
      handleReset();
    },
  });

  const headerLabel =
    action.toUpperCase() === "ADD" ? "Add new user" : `Edit "${$form.email}"`;
  const saveButtonLabel =
    action.toUpperCase() === "ADD" ? "Add new user" : "Save changes";
  $: isReadyToSubmit =
    $form.firstName && $form.lastName && $form.email && $form.role;
  
  const getAllowedRoles = (isSuperAdmin) => {
    const filterOutRoles = [ROLE_UMPIRE];
    if (!isSuperAdmin) {
      filterOutRoles.push(ROLE_SUPER_ADMIN);
    }
    return dictionaries.roles.filter((role) => !filterOutRoles.includes(role));
  }
</script>

<Modal
  isOpen={open}
  size="md"
  toggle={() => discard(handleReset)}
  id="addUserModal"
>
  <ModalHeader toggle={() => discard(handleReset)}>{headerLabel}</ModalHeader>
  <ModalBody>
    <Form on:submit={(values) => handleSubmit(values)}>
      <div class="row">
        <div class="col-6">
          <FormGroup>
            <Label for="firstName">First Name</Label>
            <Input
              bind:value={$form.firstName}
              on:change={handleChange}
              name="firstName"
              type="text"
              required
            />
            <FormError error={$errors.firstName} name="firstName" />
          </FormGroup>
        </div>

        <div class="col-6">
          <FormGroup>
            <Label for="lastName">Last Name</Label>
            <Input
              bind:value={$form.lastName}
              on:change={handleChange}
              name="lastName"
              type="text"
              required
            />
            <FormError error={$errors.lastName} name="lastName" />
          </FormGroup>
        </div>

        <div class="col-12">
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              bind:value={$form.email}
              on:change={handleChange}
              name="email"
              type="text"
              required
            />
            <FormError error={$errors.email} name="email" />
          </FormGroup>
        </div>

        <div class="col-6">
          <FormGroup>
            <Label>Select a role</Label>
            {#each getAllowedRoles($isSuperAdmin) as role}
              <Input
                bind:group={$form.role}
                on:change={handleChange}
                label={role}
                name="role"
                type="radio"
                value={role}
                required
              />
            {/each}
          </FormGroup>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <button
            type="submit"
            class="btn btn-success"
            disabled={isSavingInProgress || !$isModified || !isReadyToSubmit}
            >{saveButtonLabel}</button
          >
          <button
            type="button"
            class="btn btn-light"
            on:click={() => discard(handleReset)}
            disabled={isSavingInProgress}>Discard</button
          >
        </div>
      </div>
    </Form>
  </ModalBody>
</Modal>
