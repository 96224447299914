<script type="ts">
  /* Global dependencies */
  import { createEventDispatcher } from "svelte";
  import Flatpickr from "svelte-flatpickr";
  import { createForm } from "svelte-forms-lib";
  import MultiSelect, { Option } from "svelte-multiselect";
  import { fade } from "svelte/transition";
  import { Form, FormGroup, Input, Label } from "sveltestrap";
  import { array, number, object, string, mixed } from "yup";
  import printJS from "print-js";

  /* GSFT dependencies */
  import { saveAttachment, saveLink } from "../../api/attachments";
  import { getRecordPdfReport } from "../../api/violations";
  import { login } from "../../api/auth";
  import {
    downloadAttachment,
    requestAttachment,
    upLoadFile,
    clientUploadCompleted,
  } from "../../api/fileManager";
  import Spinner from "../../components/Spinner.svelte";
  import { notifications } from "../../helpers/notifications";
  import { getViolationStatusClassName } from "../../helpers/format";
  import {
    isChairUmpire,
    isGrandSlamAdmin,
    isGrandSlamSupervisor,
    isSuperAdmin,
    user,
  } from "../../store/user";
  import type { Attachment } from "../../types/attachments";
  import { GROUPFILES } from "../../types/attachments";
  import type { GsCourt } from "../../types/courts";
  import type { GetDictionariesResult } from "../../types/dictionaries";
  import type { ChairUmpire, ViolationRecord } from "../../types/violations";
  import {
    READY_TO_DOWNLOAD,
    READY_TO_UPLOAD,
    UPLOADED,
  } from "../../types/violations";
  import { CLOSED, COMPLETE, DRAFT } from "../../types/violations";
  import AttachmentLinkModal from "./AttachmentLinkModal.svelte";
  import FormError from "./FormError.svelte";
  import Messages from "./Messages.svelte";
  import ButtonSpinner from "../ButtonSpinner.svelte";
  import PrintPlayerSelectModal from "./PrintPlayerSelectModal.svelte";

  /* Props */
  export let chairUmpires: Array<ChairUmpire>;
  export let courts: Array<GsCourt>;
  export let dictionaries: GetDictionariesResult;
  export let selectedRecord: ViolationRecord;
  export const discardChanges = () => {
    handleReset();
  };
  export let isFormModified;

  /* Component */
  const dispatch = createEventDispatcher();
  const {
    nationalities,
    drawGenders,
    drawLevels,
    drawTypes,
    rounds,
    scores,
    penaltyTypes,
    violationTypes,
    fineCodes,
  } = dictionaries;
  const playersInvolvedCodes = [1, 2, 3];
  let chairUmpireNationality: string;
  const recordId = selectedRecord ? selectedRecord.id : "";
  let saveAndMove = false;
  let saveAndBack = false;
  let openMessage = false;
  let showLinkModal = false;
  let showPdfPlayerSelectModal = false;
  let linkViolationIndex = null;
  let linkViolationGroup = null;
  let linkIdx = null;
  let linkToEdit = null;
  let loading = false;
  let downloadingAttachments = [];
  let reportDownloading = false;

  let _violationRefs = [];

  /* Role and Status depends attributes */
  let multiSelectOptions: Array<Option>;
  $: multiSelectOptions = courts
    ? courts.map((el: GsCourt) => ({ label: el.name, value: el.id }))
    : [];
  $: recordStatus = selectedRecord ? selectedRecord.status : DRAFT;
  $: showSupervisorSection =
    ($isGrandSlamAdmin || $isGrandSlamSupervisor || $isSuperAdmin) &&
    recordStatus !== DRAFT;
  $: disableSupervisorSection = $isChairUmpire;

  $: showAppealSection =
    ($isGrandSlamAdmin || $isGrandSlamSupervisor || $isSuperAdmin) &&
    (recordStatus === COMPLETE || recordStatus === CLOSED);
  $: disableAppealSection = $isChairUmpire;
  $: disableAll =
    recordStatus === CLOSED ||
    recordStatus === COMPLETE ||
    ($isChairUmpire && recordStatus !== DRAFT);
  $: disableAddOffendingPlayer =
    ($form.drawType === "Singles" && $form.offendingPlayers.length > 0) ||
    $form.offendingPlayers.length > 1;
  $: disableAddOpponentPlayer =
    ($form.drawType === "Singles" && $form.opponentPlayers.length > 0) ||
    $form.opponentPlayers.length > 1;

  $: firstPlayerName= $form.offendingPlayers.length > 0
    ? `${$form.offendingPlayers[0].firstName[0]}. ${$form.offendingPlayers[0].lastName}`
    : "";
  
  $: secondPlayerName= $form.drawType === "Doubles" && $form.offendingPlayers.length > 1
    ? `${$form.offendingPlayers[1].firstName[0]}. ${$form.offendingPlayers[1].lastName}`
    : "";
  
  $: playersInvolvedCodeToNames = (code: number) => {
    if ($form.drawType === "Singles") {
      const firstPlayerName =
        $form.offendingPlayers.length > 0
          ? `${$form.offendingPlayers[0].firstName[0]}. ${$form.offendingPlayers[0].lastName}`
          : "";
      if (code === 1) {
        return `${code} - ${firstPlayerName}`;
      }
      if (code > 1) {
        return `${code} - N/A`;
      }
    } else if ($form.drawType === "Doubles") {
      const firstPlayerName =
        $form.offendingPlayers.length > 0
          ? `${
              $form.offendingPlayers[0].firstName
                ? $form.offendingPlayers[0].firstName[0]
                : ""
            }. ${$form.offendingPlayers[0].lastName}`
          : "";
      const secondsPlayerName =
        $form.offendingPlayers.length > 1
          ? `${
              $form.offendingPlayers[1].firstName
                ? $form.offendingPlayers[1].firstName[0]
                : ""
            }. ${$form.offendingPlayers[1].lastName}`
          : "";
      if (code === 1) {
        return `${code} - ${firstPlayerName}`;
      } else if (code === 2) {
        return `${code} - ${secondsPlayerName}`;
      } else if (code === 3) {
        return `${code} - ${firstPlayerName} / ${secondsPlayerName}`;
      }
    }

    return code;
  };
  $: showPrintButton =
    ($isGrandSlamAdmin || $isGrandSlamSupervisor || $isSuperAdmin) &&
    (recordStatus === COMPLETE || recordStatus === CLOSED);

  /* Record Form */
  const chairUmpireChanged = (chairUmpire: ChairUmpire) => {
    if (chairUmpire) {
      chairUmpireNationality = chairUmpire.nationality;
      updateValidateField("chairUmpire", chairUmpire);
    }
  };

  const handleChangeDrawType = (ev: Event) => {
    const target = ev.target as HTMLTextAreaElement;
    if (target.value === "Singles") {
      $form.violations.forEach((e) => (e.playersInvolvedCode = 1));
      if (
        $form.offendingPlayers.length > 1 ||
        $form.opponentPlayers.length > 1
      ) {
        if (
          confirm(
            "This will remove extra players from teams. Do you want to proceed?"
          )
        ) {
          if ($form.offendingPlayers.length > 1) {
            removeOffendingPlayer(1)();
          }
          if ($form.opponentPlayers.length > 1) {
            removeOpponentPlayer(1)();
          }
        }
      }
      if ($form.offendingPlayers.length === 0) {
        addOffendingPlayer();
      }
      if ($form.opponentPlayers.length === 0) {
        addOpponentPlayers();
      }
    } else {
      if ($form.offendingPlayers.length === 0) {
        addOffendingPlayer();
        addOffendingPlayer();
      }
      if ($form.opponentPlayers.length === 0) {
        addOpponentPlayers();
        addOpponentPlayers();
      }

      if ($form.offendingPlayers.length === 1) {
        addOffendingPlayer();
      }
      if ($form.opponentPlayers.length === 1) {
        addOpponentPlayers();
      }
    }
    updateValidateField("drawType", target.value);
  };

  const playerValidation = object().shape({
    tourId: string(),
    firstName: string().required("Player First Name is required"),
    lastName: string().required("Player Last Name is required"),
    nationality: string().required("Player Nationality is required"),
  });
  
  const {
    form,
    errors,
    state,
    handleChange,
    updateValidateField,
    handleSubmit,
    handleReset,
    touched,
    isModified,
    isValid,
  } = createForm({
    initialValues: {
      /* Base fields */
      chairUmpire: selectedRecord
        ? chairUmpires
            .map((el) => el.id)
            .find((el) => el === selectedRecord.chairUmpireIds[0])
        : $isChairUmpire
        ? chairUmpires.find((el) => el.email.toLowerCase() == $user.email.toLowerCase()).id
        : "",
      matchId: selectedRecord ? selectedRecord.matchId : "",
      matchResult: selectedRecord ? selectedRecord.matchResult : "",
      matchWinner: selectedRecord ? selectedRecord.matchWinner : "",
      drawGender: selectedRecord ? selectedRecord.drawGender : "",
      drawLevel: selectedRecord ? selectedRecord.drawLevel : "",
      drawType: selectedRecord ? selectedRecord.drawType : "",
      round: selectedRecord ? selectedRecord.round : "",
      matchDate: selectedRecord ? selectedRecord.matchDate : "",
      courts:
        selectedRecord && selectedRecord.courtIds.length > 0
          ? courts
              .filter((el: GsCourt) => selectedRecord.courtIds.includes(el.id))
              .map((el: GsCourt) => ({ value: el.id, label: el.name }))
          : [],
      /* Appeal Fields */
      appealNotes: selectedRecord ? selectedRecord.appealNotes : "",
      /* Offending Players */
      offendingPlayers:
        selectedRecord && selectedRecord.offendingPlayers.length > 0
          ? selectedRecord.offendingPlayers
          : [],
      /* Opponent Players */
      opponentPlayers:
        selectedRecord && selectedRecord.opponentPlayers.length > 0
          ? selectedRecord.opponentPlayers
          : [],
      /* Violations */
      violations:
        selectedRecord && selectedRecord.violations.length > 0
          ? selectedRecord.violations
          : [],
      /* Violations */
      attachments:
        selectedRecord && selectedRecord.attachments.length > 0
          ? selectedRecord.attachments
          : [],
    },
    validationSchema: object().shape({
      /* Base fields */
      chairUmpire: string().required("Chair Umpire is required"),
      matchId: string().required("Match ID is required"),
      drawGender: string().required("Draw Gender is required"),
      drawLevel: string().required("Draw Level is required"),
      drawType: string().required("Draw Type is required"),
      round: string().required("Round is required"),
      matchDate: string().required("Match Date is required"),
      courts: array()
        .of(
          object().shape({
            label: string(),
            value: string(),
          })
        )
        .required()
        .defined(),
      /* Appeal Fields */
      appealNotes: mixed().test({
        name: "Show Appeal Section",
        message: "Appeal Description is required",
        test: function (value) {
          if (showAppealSection) {
            return !(value === "" || value === null);
          }
          return true;
        },
      }),
      /* Offending Players */
      offendingPlayers: array().of(playerValidation),
      /* Opponent Players */
      opponentPlayers: array().of(playerValidation),
      /* Violations */
      violations: array().of(
        object().shape({
          /* Base fields */
          set: number()
            .typeError("Set must be a number")
            .nullable()
            .required("Set is required"),
          game: number()
            .typeError("Game must be a number")
            .nullable()
            .required("Game is required"),
          point: number()
            .typeError("Point must be a number")
            .nullable()
            .required("Point is required"),
          score: string().nullable().required("Score is required"),
          playersInvolvedCode: number()
            .nullable()
            .required("Players Involved Code is required"),
          violationType: string()
            .nullable()
            .required("Violation Type is required"),
          penaltyType: mixed().test({
            
            message: "Penalty Type is required",
            test: function (value, ctx) {
                if(ctx.parent.score != 'Post-Match' &&
                ctx.parent.score != 'Pre-Match'){
                  return !(value === "" || value === null);
                }else{
                  return true;
                }
              
              
              
            },
          }),
          description: string().nullable().required("Description is required"),

          /* Grand Slam Supervisor fields */
          fineAmount: mixed().test({
            name: "Show Supervisor Section",
            message: "Fine Amount is required and must be a number",
            test: function (value) {
              if (showSupervisorSection) {
                return !(value === "" || value === null) && !isNaN(value);
              }
              return true;
            },
          }),
          fineCode: mixed().test({
            name: "Show Supervisor Section",
            message: "Fine Code is required",
            test: function (value) {
              if (showSupervisorSection) {
                return !(value === "" || value === null);
              }
              return true;
            },
          }),
          fineDescription: mixed().test({
            name: "Show Supervisor Section",
            message: "Fine Description is required",
            test: function (value) {
              if (showSupervisorSection) {
                return !(value === "" || value === null);
              }
              return true;
            },
          }),
          /* Appeal Fields */
          postFineAmount: mixed().test({
            name: "Show Appeal Section",
            message: "Post Fine Amount is required and must be a number",
            test: function (value) {
              if (showAppealSection) {
                return !(value === "" || value === null) && !isNaN(value);
              }
              return true;
            },
          }),
        })
      ),
    }),
    onSubmit: (values) => {
      const {
        matchId,
        drawGender,
        drawLevel,
        drawType,
        round,
        matchResult,
        matchWinner,
        matchDate,
        courts,
        offendingPlayers,
        opponentPlayers,
        violations,
        appealNotes,
        attachments,
      } = values;

      if (saveAndMove) {
        if (
          offendingPlayers.length === 0 ||
          opponentPlayers.length === 0 ||
          violations.length === 0
        ) {
          notifications.danger(
            "Please fill the form (Player, Opponent and Violation) to move to next status."
          );
          return;
        }
      }

      let data: ViolationRecord = {
        status: recordStatus,
        matchId,
        drawGender,
        drawLevel,
        drawType,
        round,
        matchResult,
        matchWinner,
        matchDate,
        courtIds: courts.map((el) => el.value),
        chairUmpireIds: [+values.chairUmpire],
        offendingPlayers,
        opponentPlayers,
        violations,
        appealNotes,
        attachments,
        new: false,
        returned: false,
        selectedToDelete: false
      };
      if (recordId) {
        data = { id: recordId, ...data };
      }

      dispatch("submit", {
        data,
        saveAndMove,
        saveAndBack,
      });
    },
  });

  $: {
    isFormModified = $isModified;
  }

  const addOffendingPlayer = () => {
    $form.offendingPlayers = $form.offendingPlayers.concat({
      tourId: "",
      firstName: "",
      lastName: "",
      nationality: "",
    });
    // @ts-ignore
    $errors.offendingPlayers = $errors.offendingPlayers.concat({
      tourId: "",
      firstName: "",
      lastName: "",
      nationality: "",
    });
  };

  const removeOffendingPlayer = (i: number) => () => {
    $form.offendingPlayers = $form.offendingPlayers.filter((u, j) => j !== i);
    // @ts-ignore
    $errors.offendingPlayers = $errors.offendingPlayers.filter(
      (u, j) => j !== i
    );
  };

  const addOpponentPlayers = () => {
    $form.opponentPlayers = $form.opponentPlayers.concat({
      tourId: "",
      firstName: "",
      lastName: "",
      nationality: "",
    });
    // @ts-ignore
    $errors.opponentPlayers = $errors.opponentPlayers.concat({
      tourId: "",
      firstName: "",
      lastName: "",
      nationality: "",
    });
  };

  const removeOpponentPlayer = (i: number) => () => {
    $form.opponentPlayers = $form.opponentPlayers.filter((u, j) => j !== i);
    // @ts-ignore
    $errors.opponentPlayers = $errors.opponentPlayers.filter((u, j) => j !== i);
  };

  const addViolation = () => {
    $form.violations = $form.violations.concat({
      description: "",
      fineAmount: null,
      fineCode: "",
      fineDescription: "",
      game: null,
      penaltyType: "",
      playersInvolvedCode: null,
      point: null,
      postFineAmount: null,
      score: "",
      set: null,
      violationType: "",
      attachments: [],
    });
    // @ts-ignore
    $errors.violations = $errors.violations.concat({
      description: "",
      fineAmount: null,
      fineCode: "",
      fineDescription: "",
      game: null,
      penaltyType: "",
      playersInvolvedCode: null,
      point: null,
      postFineAmount: null,
      score: "",
      set: null,
      violationType: "",
      attachments: [],
    });
    setTimeout(() => {
      scrollIntoView(_violationRefs[$form.violations.length - 1]);
    });
  };

  const removeViolation = (i) => () => {
    $form.violations = $form.violations.filter((u, j) => j !== i);
    // @ts-ignore
    $errors.violations = $errors.violations.filter((u, j) => j !== i);
  };

  const addAttachment = (index, groupFile, uploadResult) => {
    let attachment: Attachment = {
      violationId: index == -1 ? null : $form.violations[index].id,
      violationRecordId: selectedRecord.id,
      url: uploadResult.key,
      groupFile: groupFile,
      type: "File",
    };

    saveAttachment(attachment)
      .then(() => {
        if (index == -1) {
          $form.attachments = [...$form.attachments, attachment];
        } else {
          $form.violations[index].attachments = [
            ...$form.violations[index].attachments,
            attachment,
          ];
        }

        loading = false;
      })
      .catch((err) => {
        console.log(err);
        notifications.danger("There was an error uploading file.");
        loading = false;
      });
  };

  const addAttachmentToRecord = (index, groupFile, attachment) => {
    if (index == -1) {
      $form.attachments = [...$form.attachments, attachment];
    } else {
      $form.violations[index].attachments = [
        ...$form.violations[index].attachments,
        attachment,
      ];
    }
  };

  const removeFileLink = (violationIndex, idToRemove, groupFile) => {
    if (violationIndex == -1) {
      $form.attachments = $form.attachments.filter(
        (u, j) => u.id !== idToRemove
      );
    } else {
      $form.violations[violationIndex].attachments = $form.violations[
        violationIndex
      ].attachments.filter((u, j) => u.id !== idToRemove);
    }
  };

  const showAddLinkModal = (index, groupFile) => {
    linkViolationIndex = index;
    linkViolationGroup = groupFile;
    showLinkModal = true;
  };

  const hideAddLinkModal = () => {
    linkViolationIndex = null;
    linkViolationGroup = null;
    linkIdx = null;
    linkToEdit = null;
    showLinkModal = false;
  };

  const addAttachmentLink = (url, index, groupFile) => {
    let link: Attachment = {
      url: url,
      groupFile: groupFile,
      type: "Link",
    };
    loading = true;
    saveLink(link)
      .then((savedLink) => {
        if (index == -1) {
          $form.attachments = [...$form.attachments, savedLink];
        } else {
          $form.violations[index].attachments = [
            ...$form.violations[index].attachments,
            savedLink,
          ];
        }
        loading = false;
        showLinkModal = false;
      })
      .catch((err) => {
        console.log(err);
        notifications.danger("There was an error uploading file.");
        loading = false;
      });
  };

  const replaceAttachmentLink = (url, index, groupFile, idxToReplace) => {
    let link: Attachment = {
      url: url,
      groupFile: groupFile,
      type: "Link",
    };
    loading = true;
    saveLink(link)
      .then((savedLink) => {
        if (index == -1) {
          $form.attachments[idxToReplace] = savedLink;
        } else {
          $form.violations[index].attachments[idxToReplace] = savedLink;
        }
        loading = false;
        showLinkModal = false;
      })
      .catch((err) => {
        console.log(err);
        notifications.danger("There was an error uploading file.");
        loading = false;
      });
  };

  const removeAttachmentLink = (violationIndex, idToRemove, groupFile) => {
    if (violationIndex == -1) {
      $form.attachments = $form.attachments.filter(
        (u, j) => u.id !== idToRemove
      );
    } else {
      $form.violations[violationIndex].attachments = $form.violations[
        violationIndex
      ].attachments.filter((u, j) => u.id !== idToRemove);
    }
  };

  const upLoadAttachment = (index, groupFile) => (e) => {
    loading = true;
    let file = e.target.files[0];
    upLoadFile(file, groupFile)
      .then((result) => {
        if (result.awsUploadResult.ok) {
          clientUploadCompleted(result.upLoadFileResult.attachment.id)
            .then((result) => {})
            .catch((err) => {
              console.error(err);
              notifications.danger("There was an error uploading file.");
            });
          addAttachmentToRecord(
            index,
            groupFile,
            result.upLoadFileResult.attachment
          );
        } else {
          console.error(result);
          notifications.danger("There was an error uploading file.");
        }
      })
      .catch((err) => {
        console.error(err);
        notifications.danger("There was an error uploading file.");
      })
      .finally(() => {
        loading = false;
      });
  };

  const handleSelectChange = (event: Event) => {
    // not worked in Chrome, redundant
    // handleChange(event);
  };

  const formatUrlForFileDownload = (url) => {
    return url.substring(url.indexOf("_") + 1, url.length);
  };

  const printReport = () => {
    if ($form.drawType === "Singles"){
      printPlayerReport("");
    }else{
      showPdfPlayerSelectModal=true;
    }
  };

  const printPlayerReport = (player) => {
    reportDownloading = true;
    getRecordPdfReport(selectedRecord?.id, player).then((pdf) => {
      printJS({
        printable: pdf,
        type: "pdf",
        onLoadingEnd: () => (reportDownloading = false),
      });
    });
  };

  const downloadAttachmentWithProgress = (id: number, fileName: string) => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);
    downloadingAttachments.push(id);
    downloadingAttachments = downloadingAttachments;

    requestAttachment(id).then((blobby) => {
      let objectUrl = window.URL.createObjectURL(blobby);

      anchor.href = objectUrl;
      anchor.download = `${fileName}`;
      anchor.click();

      window.URL.revokeObjectURL(objectUrl);
      const index = downloadingAttachments.indexOf(id);
      if (index > -1) {
        downloadingAttachments.splice(index, 1);
      }
      downloadingAttachments = downloadingAttachments;
    });
  };

  const openLink = (link: string) => {
    window.open(link, "_blank");
  };

  const changeLink = (violationIndex, groupFile, idxToReplace, link) => {
    linkViolationIndex = violationIndex;
    linkViolationGroup = groupFile;
    linkIdx = idxToReplace;
    linkToEdit = link;
    showLinkModal = true;
  };

  const scrollIntoView = (el) => {
    if (!el) return;
    el.scrollIntoView({
      behavior: "smooth",
    });
  };
</script>

<div>
  {#if showLinkModal}
    <AttachmentLinkModal
      bind:open={showLinkModal}
      link={linkToEdit}
      on:proceed={(ev) => {
        const { url } = ev.detail;
        
        if (linkIdx != null) {
           replaceAttachmentLink(
             url,
             linkViolationIndex,
             linkViolationGroup,
             linkIdx
           );
         } else {
           addAttachmentLink(url, linkViolationIndex, linkViolationGroup);
        }
      }}
      on:cancel={hideAddLinkModal}
    />
  {/if}
  {#if showPrintButton}
   <PrintPlayerSelectModal
      bind:open={showPdfPlayerSelectModal}
      firstPlayer={firstPlayerName}
      secondPlayer={secondPlayerName}
      codes={$form.violations.map(x=>x.playersInvolvedCode)}
      on:proceed={(ev)=>{
        printPlayerReport(ev.detail.player);
      }}
    />
  {/if}
  <Spinner {loading} />
  <Form
    on:submit={(ev) => {
      ev.preventDefault();
      const typeOfSave = ev.submitter.getAttribute("value");
      saveAndMove = typeOfSave === "move";
      saveAndBack = typeOfSave === "back";
      handleSubmit(ev);
    }}
    class="mt-2 px-3"
    id={selectedRecord ? "recordForm" : "newRecordForm"}
  >
    {#if selectedRecord}
      <div class="row align-items-center">
        <div class="col-7">
          <em class="record-id me-2" title={selectedRecord.recordId}
            >{selectedRecord.recordId}</em
          >
          <span
            class="badge label-status me-2 {getViolationStatusClassName(
              selectedRecord.status
            )}">{selectedRecord.status}</span
          >
        </div>
        <div class="col-5 text-end">
          {#if showPrintButton}
            <button
              class="btn btn-info"
              type="button"
              on:click={printReport}
              disabled={reportDownloading}
            >
              <ButtonSpinner loading={reportDownloading} />
              <i class="fas fa-print" /> Print Report
            </button>
          {/if}
          <button
            class="btn btn-info"
            type="button"
            on:click={() => (openMessage = !openMessage)}
          >
            <i class="bi bi-chat-left-dots" />
          </button>
        </div>
      </div>
    {/if}
    <fieldset disabled={$isChairUmpire}>
      <div class="row">
        <div class="col-12">
          <FormGroup>
            <Label for={`chairUmpire${recordId}`}>Chair Umpire</Label>
            <!-- <AutoComplete
              bind:selectedItem={$form.chairUmpire}
              inputClassName="form-control form-autocomplete"
              inputId={`chairUmpire${recordId}`}
              items={chairUmpires}
              labelFieldName="name"
              onChange={chairUmpireChanged}
              valueFieldName="id"
              disabled={$isChairUmpire}
              lock={!$isChairUmpire}
            /> -->
            <Input
              bind:value={$form.chairUmpire}
              id={`chairUmpire${recordId}`}
              name="chairUmpire"
              type="select"
              disabled={$isChairUmpire}
            >
              {#if chairUmpires}
                {#each chairUmpires as chairUmpire}
                  <option value={chairUmpire.id} disabled={!chairUmpire.active}
                    >{chairUmpire.firstName} {chairUmpire.lastName}</option
                  >
                {/each}
              {/if}
            </Input>
            <FormError error={$errors.chairUmpire} name="chairUmpire" />
          </FormGroup>
        </div>
      </div>
    </fieldset>
    <fieldset disabled={disableAll}>
      <legend>Match Details</legend>
      <div class="row">
        <div class="col-12">
          <FormGroup>
            <Label for={`matchId${recordId}`}>Scoring System Match ID</Label>
            <Input
              bind:value={$form.matchId}
              id={`matchId${recordId}`}
              name="matchId"
              on:change={handleChange}
              placeholder="Example: WS101"
              type="text"
              required
            />
            <FormError error={$errors.matchId} name="matchId" />
          </FormGroup>
        </div>
        <div class="col-6">
          <FormGroup>
            <Label>Draw</Label>
            {#if drawGenders}
              {#each drawGenders as drawGender}
                <Input
                  bind:group={$form.drawGender}
                  id={`${drawGender}${recordId}`}
                  label={drawGender}
                  name="drawGender"
                  on:change={handleChange}
                  type="radio"
                  value={drawGender}
                  required
                />
              {/each}
            {/if}
            <FormError error={$errors.drawGender} name="drawGender" />
          </FormGroup>
        </div>
        <div class="col-6">
          <FormGroup>
            <Label>Main/Qualifying</Label>
            {#if drawLevels}
              {#each drawLevels as drawLevel}
                <Input
                  bind:group={$form.drawLevel}
                  id={`${drawLevel}${recordId}`}
                  label={drawLevel}
                  name="drawLevel"
                  on:change={handleChange}
                  type="radio"
                  value={drawLevel}
                  required
                />
              {/each}
            {/if}
            <FormError error={$errors.drawLevel} name="drawLevel" />
          </FormGroup>
        </div>
        <div class="col-6">
          <FormGroup>
            <Label>Singles/Doubles</Label>
            {#if drawTypes}
              {#each drawTypes as drawType}
                <Input
                  bind:group={$form.drawType}
                  id={`${drawType}${recordId}`}
                  label={drawType}
                  name="drawType"
                  on:change={handleChangeDrawType}
                  type="radio"
                  value={drawType}
                  required
                />
              {/each}
            {/if}
            <FormError error={$errors.drawType} name="drawType" />
          </FormGroup>
        </div>
        <div class="col-6">
          <FormGroup>
            <Label for={`round${recordId}`}>Round</Label>
            <Input
              bind:value={$form.round}
              id={`round${recordId}`}
              name="round"
              on:change={handleChange}
              type="select"
              required
            >
              {#if rounds}
                {#each rounds as round}
                  <option value={round}>{round}</option>
                {/each}
              {/if}
            </Input>
            <FormError error={$errors.round} name="round" />
          </FormGroup>
        </div>

        <div class="col-6">
          <FormGroup>
            <Label for={`matchDate${recordId}`}>Match Date</Label>
            <div class="input-group">
              <Flatpickr
                bind:formattedValue={$form.matchDate}
                bind:value={$form.matchDate}
                class="form-control form-flatpickr"
                options={{
                  maxDate: new Date(),
                }}
                id={`matchDate${recordId}`}
                name="matchDate"
                required
              />
              <label for="matchDate" class="input-group-text">
                <i class="fas fa-calendar text-dark" />
              </label>
            </div>
            <FormError error={$errors.matchDate} name="matchDate" />
          </FormGroup>
        </div>
        <div class="col-6">
          <FormGroup>
            <Label for={`courtId${recordId}`}>Match Court</Label>
            <MultiSelect
              bind:selected={$form.courts}
              options={multiSelectOptions}
              disabled={disableAll}
              required
            />
          </FormGroup>
        </div>
        <div class="col-6">
          <FormGroup>
            <Label for={`matchWinner${recordId}`}>Match Winner</Label>
            <Input
              bind:value={$form.matchWinner}
              id={`matchWinner${recordId}`}
              name="matchWinner"
              placeholder="Example: J. Doe"
              type="text"
              
            />
            
          </FormGroup>
        </div>

        <div class="col-6">
          <FormGroup>
            <Label for={`matchResult${recordId}`}>Match Result</Label>
            <Input
              bind:value={$form.matchResult}
              id={`matchResult${recordId}`}
              name="matchResult"
              placeholder="6-0 6-0 6-0"
              type="text"
              
            />
            
          </FormGroup>
        </div>
        
      </div>
    </fieldset>
    <fieldset disabled={disableAll}>
      <legend>Player Team</legend>
      {#each $form.offendingPlayers as player, j}
        <div class="row">
          <div class="col-2">
            <FormGroup>
              <Label for={`offendingPlayerTourID${j}`}>Tour ID</Label>
              <Input
                bind:value={$form.offendingPlayers[j].tourId}
                id={`offendingPlayerTourID${j}`}
                name={`offendingPlayers[${j}].tourId`}
                on:blur={handleChange}
                on:change={handleChange}
                placeholder="Enter Tour ID"
              />
              <FormError
                error={$errors.offendingPlayers[j] &&
                  $errors.offendingPlayers[j]["tourId"]}
                name="offendingPlayerTourID"
              />
            </FormGroup>
          </div>
          <div class="col-4">
            <FormGroup>
              <Label for={`offendingPlayerFirstName${j}`}>First Name</Label>
              <Input
                bind:value={$form.offendingPlayers[j].firstName}
                id={`offendingPlayerFirstName${j}`}
                name={`offendingPlayers[${j}].firstName`}
                on:blur={handleChange}
                on:change={handleChange}
                placeholder="Enter First Name"
              />
              <FormError
                error={$errors.offendingPlayers[j] &&
                  $errors.offendingPlayers[j]["firstName"]}
                name="offendingPlayerFirstName"
              />
            </FormGroup>
          </div>
          <div class="col-4">
            <FormGroup>
              <Label for={`offendingPlayerLastName${j}`}>Last Name</Label>
              <Input
                bind:value={$form.offendingPlayers[j].lastName}
                id={`offendingPlayerLastName${j}`}
                name={`offendingPlayers[${j}].lastName`}
                on:blur={handleChange}
                on:change={handleChange}
                placeholder="Enter Last Name"
              />
              <FormError
                error={$errors.offendingPlayers[j] &&
                  $errors.offendingPlayers[j]["lastName"]}
                name="offendingPlayerLastName"
              />
            </FormGroup>
          </div>
          <div class="col-2">
            <FormGroup>
              <Label for={`offendingPlayerNationality${j}`}>Nationality</Label>
              <Input
                bind:value={$form.offendingPlayers[j].nationality}
                id={`offendingPlayerNationality${j}`}
                name={`offendingPlayers[${j}].nationality`}
                on:blur={handleChange}
                on:change={handleChange}
                type="select"
              >
                {#if nationalities}
                  {#each nationalities as nationality}
                    <option>{nationality}</option>
                  {/each}
                {/if}
              </Input>
              <FormError
                error={$errors.offendingPlayers[j] &&
                  $errors.offendingPlayers[j]["nationality"]}
                name="offendingPlayerNationality"
              />
            </FormGroup>
          </div>
        </div>
      {/each}
    </fieldset>
    <fieldset disabled={disableAll}>
      <legend>Opponent Team</legend>
      {#each $form.opponentPlayers as user, j}
        <div class="row">
          <div class="col-2">
            <FormGroup>
              <Label for={`opponentPlayerTourID${j}`}>Tour ID</Label>
              <Input
                bind:value={$form.opponentPlayers[j].tourId}
                id={`opponentPlayerTourID${j}`}
                name={`opponentPlayers[${j}].tourId`}
                on:blur={handleChange}
                on:change={handleChange}
                placeholder="Enter Tour ID"
              />
              <FormError
                error={$errors.opponentPlayers[j] &&
                  $errors.opponentPlayers[j]["tourId"]}
                name="opponentPlayerTourID"
              />
            </FormGroup>
          </div>
          <div class="col-4">
            <FormGroup>
              <Label for={`opponentPlayerFirstName${j}`}>First Name</Label>
              <Input
                bind:value={$form.opponentPlayers[j].firstName}
                id={`opponentPlayerFirstName${j}`}
                name={`opponentPlayers[${j}].firstName`}
                on:blur={handleChange}
                on:change={handleChange}
                placeholder="Enter First Name"
              />
              <FormError
                error={$errors.opponentPlayers[j] &&
                  $errors.opponentPlayers[j]["firstName"]}
                name="opponentPlayerFirstName"
              />
            </FormGroup>
          </div>
          <div class="col-4">
            <FormGroup>
              <Label for={`opponentPlayerLastName${j}`}>Last Name</Label>
              <Input
                bind:value={$form.opponentPlayers[j].lastName}
                id={`opponentPlayerLastName${j}`}
                name={`opponentPlayers[${j}].lastName`}
                on:blur={handleChange}
                on:change={handleChange}
                placeholder="Enter Last Name"
              />
              <FormError
                error={$errors.opponentPlayers[j] &&
                  $errors.opponentPlayers[j]["lastName"]}
                name="opponentPlayerLastName"
              />
            </FormGroup>
          </div>
          <div class="col-2">
            <FormGroup>
              <Label for={`opponentPlayerNationality${j}`}>Nationality</Label>
              <Input
                bind:value={$form.opponentPlayers[j].nationality}
                id={`opponentPlayerNationality${j}`}
                name={`opponentPlayers[${j}].nationality`}
                on:blur={handleChange}
                on:change={handleChange}
                type="select"
              >
                {#if nationalities}
                  {#each nationalities as nationality}
                    <option>{nationality}</option>
                  {/each}
                {/if}
              </Input>
              <FormError
                error={$errors.opponentPlayers[j] &&
                  $errors.opponentPlayers[j]["nationality"]}
                name="opponentPlayerNationality"
              />
            </FormGroup>
          </div>
        </div>
      {/each}
    </fieldset>
    <div class="row">
      <div class="col-12 text-center pb-3">
        <button
          type="button"
          class="btn btn-light btn-xs"
          on:click={addViolation}
          data-cy="addViolation"
          disabled={disableAll}
        >
          Add Violation
        </button>
      </div>
    </div>
    {#each $form.violations as violation, j}
      <fieldset bind:this={_violationRefs[j]}>
        <legend>
          Code Violation #{j + 1}
          <button
            type="button"
            class="btn btn-warning btn-sm"
            on:click={removeViolation(j)}
          >
            &times;
          </button>
        </legend>
        <fieldset disabled={disableAll}>
          <div class="row">
            <div class="col-3">
              <FormGroup>
                <Label for={`violationSet${j}`}>Set</Label>
                <Input
                  bind:value={$form.violations[j].set}
                  id={`violationSet${j}`}
                  name={`violations[${j}].set`}
                  on:blur={handleChange}
                  on:change={handleChange}
                  placeholder="Enter Set"
                />
                {#if $errors.violations[j] && $errors.violations[j]["set"]}
                  <small class="text-danger">{$errors.violations[j]["set"]}</small
                  >
                {/if}
              </FormGroup>
            </div>
            <div class="col-3">
              <FormGroup>
                <Label for={`violationGame${j}`}>Game</Label>
                <Input
                  bind:value={$form.violations[j].game}
                  id={`violationGame${j}`}
                  name={`violations[${j}].game`}
                  on:blur={handleChange}
                  on:change={handleChange}
                  placeholder="Enter Game"
                />
                {#if $errors.violations[j] && $errors.violations[j]["game"]}
                  <small class="text-danger"
                    >{$errors.violations[j]["game"]}</small
                  >
                {/if}
              </FormGroup>
            </div>
            <div class="col-3">
              <FormGroup>
                <Label for={`violationPoint${j}`}>Point</Label>
                <Input
                  bind:value={$form.violations[j].point}
                  id={`violationPoint${j}`}
                  name={`violations[${j}].point`}
                  on:blur={handleChange}
                  on:change={handleChange}
                  placeholder="Enter Point"
                />
                {#if $errors.violations[j] && $errors.violations[j]["point"]}
                  <small class="text-danger"
                    >{$errors.violations[j]["point"]}</small
                  >
                {/if}
              </FormGroup>
            </div>
            <div class="col-3">
              <FormGroup>
                <Label for={`violationScore${j}`}>Score</Label>
                <select
                  class="form-select"
                  bind:value={$form.violations[j].score}
                  id={`violationScore${j}`}
                  name={`violations[${j}].score`}
                  
                  on:change={(ev)=> {
                    //console.log($form.violations[j].score);
                    if($form.violations[j].score == 'Post-Match' ||
                    $form.violations[j].score == 'Pre-Match'){
                      $form.violations[j].set = 0;
                      $form.violations[j].game = 0;
                      $form.violations[j].point = 0;
                      $form.violations[j].penaltyType = null;
                    }
                  } }

                >
                {#if scores}
                {#each scores as score}
                  <option>{score}</option>
                {/each}
              {/if}
                </select>
                
                {#if $errors.violations[j] && $errors.violations[j]["score"]}
                  <small class="text-danger"
                    >{$errors.violations[j]["score"]}</small
                  >
                {/if}
              </FormGroup>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <FormGroup>
                <Label for={`violationPlayersInvolvedCode${j}`}>
                  Players Involved Code
                </Label>
                <Input
                  bind:value={$form.violations[j].playersInvolvedCode}
                  id={`violationPlayersInvolvedCode${j}`}
                  name={`violations[${j}].playersInvolvedCode`}
                  on:change={handleSelectChange}
                  placeholder="Enter Players Involved Code"
                  type="select"
                >
                  {#if playersInvolvedCodes}
                    {#each playersInvolvedCodes as playersInvolvedCode}
                      <option
                        value={playersInvolvedCode}
                        disabled={$form.drawType === "Singles" &&
                          playersInvolvedCode > 1}
                      >
                        {playersInvolvedCodeToNames(playersInvolvedCode)}
                      </option>
                    {/each}
                  {/if}
                </Input>
                {#if $errors.violations[j] && $errors.violations[j]["playersInvolvedCode"]}
                  <small class="text-danger">
                    {$errors.violations[j]["playersInvolvedCode"]}
                  </small>
                {/if}
              </FormGroup>
            </div>
            <div class="col-12">
              <FormGroup>
                <Label for={`violationViolationType${j}`}>Violation Type</Label>
                <Input
                  bind:value={$form.violations[j].violationType}
                  id={`violationViolationType${j}`}
                  name={`violations[${j}].violationType`}
                  on:blur={handleChange}
                  on:change={handleChange}
                  placeholder="Enter Violation Type"
                  type="select"
                >
                  {#if violationTypes}
                    {#each violationTypes as violationType}
                      <option>{violationType}</option>
                    {/each}
                  {/if}
                </Input>
                {#if $errors.violations[j] && $errors.violations[j]["violationType"]}
                  <small class="text-danger">
                    {$errors.violations[j]["violationType"]}
                  </small>
                {/if}
              </FormGroup>
            </div>
            <div class="col-12">
              <FormGroup>
                <Label for={`violationPenaltyType${j}`}>Penalty Type</Label>
                <Input
                  bind:value={$form.violations[j].penaltyType}
                  id={`violationPenaltyType${j}`}
                  name={`violations[${j}].penaltyType`}
                  on:blur={handleChange}
                  on:change={handleChange}
                  placeholder="Enter Penalty Type"
                  type="select"
                >
                  {#if penaltyTypes}
                    {#each penaltyTypes as penaltyType}
                      <option>{penaltyType}</option>
                    {/each}
                  {/if}
                </Input>
                {#if $errors.violations[j] && $errors.violations[j]["penaltyType"]}
                  <small class="text-danger">
                    {$errors.violations[j]["penaltyType"]}
                  </small>
                {/if}
              </FormGroup>
            </div>
            <div class="col-12">
              <FormGroup>
                <Label for={`violationDescription${j}`}>
                  Violation Description
                </Label>
                <Input
                  bind:value={$form.violations[j].description}
                  id={`violationDescription${j}`}
                  name={`violations[${j}].description`}
                  on:blur={handleChange}
                  on:change={handleChange}
                  placeholder="Enter Description"
                  type="textarea"
                />
                {#if $errors.violations[j] && $errors.violations[j]["description"]}
                  <small class="text-danger">
                    {$errors.violations[j]["description"]}
                  </small>
                {/if}
              </FormGroup>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend
            >Violation Attachments
            <button
              type="button"
              class="btn btn-light btn-sm"
              on:click={() => {
                document
                  .getElementById(`fileViolation${j}`)
                  .click();
              }}>Upload</button
            >
            <input
              style="display:none"
              type="file"
              on:change={upLoadAttachment(j, GROUPFILES.VIOLATION)}
              id={`fileViolation${j}`}
            />
            <button
              type="button"
              class="btn btn-light btn-sm"
              on:click={() => showAddLinkModal(j, GROUPFILES.VIOLATION)}
              >Add Link</button
            >
          </legend>
          <div class="row">
            <div class="col-12">
              {#if $form.violations[j].attachments.filter((s) => s.groupFile === GROUPFILES.VIOLATION).length > 0}
                {#each $form.violations[j].attachments as file, fileIdx}
                  {#if file.groupFile === GROUPFILES.VIOLATION}
                    {#if file.type === "Link"}
                      <div class="d-flex">
                        <Input
                          bind:value={$form.violations[j].attachments[fileIdx]
                            .url}
                          name={`violations[${j}].attachments[${fileIdx}].url`}
                          on:blur={handleChange}
                          on:change={handleChange}
                          placeholder="Enter URL"
                          readonly
                          type="url"
                        />
                        <button
                          class="btn btn-sm btn-primary ms-1"
                          type="button"
                          on:click={() =>
                            changeLink(
                              j,
                              GROUPFILES.VIOLATION,
                              fileIdx,
                              $form.violations[j].attachments[fileIdx].url
                            )}><i class="fas fa-pencil-alt" /></button
                        >
                        <button
                          class="btn btn-sm btn-info ms-1"
                          type="button"
                          on:click={() =>
                            openLink(
                              $form.violations[j].attachments[fileIdx].url
                            )}><i class="fas fa-external-link-alt" /></button
                        >
                        <button
                          class="btn btn-sm btn-danger ms-1"
                          type="button"
                          on:click={() =>
                            removeAttachmentLink(
                              j,
                              file.id,
                              GROUPFILES.VIOLATION
                            )}>&times;</button
                        >
                      </div>
                    {:else}
                      <div class="attachment-pseudolink-wrapper">
                        {#if file.status === READY_TO_DOWNLOAD}
                          <span
                            class="attachment-pseudolink"
                            on:click={() => {
                              downloadAttachmentWithProgress(file.id, file.url);
                            }}
                          >
                            <Spinner
                              loading={downloadingAttachments.includes(file.id)}
                            />
                            {formatUrlForFileDownload(file.url)}
                          </span>
                        {:else if file.status === UPLOADED}
                          <span class="attachment-pseudolink ready-to-upload"
                            >{formatUrlForFileDownload(file.url)}</span
                          >
                        {:else if file.status === READY_TO_UPLOAD}
                          <span class="attachment-pseudolink ready-to-upload"
                            >{formatUrlForFileDownload(file.url)}</span
                          >
                          <small
                            >this file is not saved with record until violation
                            record is saved</small
                          >
                        {/if}
                        <button
                          class="btn btn-sm btn-danger ms-1"
                          type="button"
                          on:click={() =>
                            removeFileLink(j, file.id, GROUPFILES.VIOLATION)}
                          >&times;</button
                        >
                      </div>
                    {/if}
                  {/if}
                {/each}
              {/if}
            </div>
          </div>
        </fieldset>
        {#if showSupervisorSection}
          <fieldset>
            <legend>Grand Slam Supervisor Entry</legend>
            <div class="row">
              <fieldset disabled={disableAll}>
                <div class="col-6">
                  <FormGroup>
                    <Label for={`violationFineAmount${j}`}>Fine Amount</Label>
                    <Input
                      bind:value={$form.violations[j].fineAmount}
                      id={`violationFineAmount${j}`}
                      name={`violations[${j}].fineAmount`}
                      on:blur={handleChange}
                      on:change={handleChange}
                      placeholder="Enter Fine Amount"
                      disabled={disableSupervisorSection}
                    />
                    {#if $errors.violations[j] && $errors.violations[j]["fineAmount"]}
                      <small class="text-danger">
                        {$errors.violations[j]["fineAmount"]}
                      </small>
                    {/if}
                  </FormGroup>
                </div>
                <div class="col-6">
                  <FormGroup>
                    <Label for={`violationFineCode${j}`}>Fine Code</Label>
                    <Input
                      bind:value={$form.violations[j].fineCode}
                      id={`violationFineCode${j}`}
                      name={`violations[${j}].fineCode`}
                      on:blur={handleChange}
                      on:change={handleChange}
                      placeholder="Enter Fine Code"
                      type="select"
                      disabled={disableSupervisorSection}
                    >
                      {#if fineCodes}
                        {#each fineCodes as fineCode}
                          <option>{fineCode}</option>
                        {/each}
                      {/if}
                    </Input>
                    {#if $errors.violations[j] && $errors.violations[j]["fineCode"]}
                      <small class="text-danger">
                        {$errors.violations[j]["fineCode"]}
                      </small>
                    {/if}
                  </FormGroup>
                </div>
                <div class="col-12">
                  <FormGroup>
                    <Label for={`violationFineDescription${j}`}>
                      Fine Description
                    </Label>
                    <Input
                      bind:value={$form.violations[j].fineDescription}
                      id={`violationFineDescription${j}`}
                      name={`violations[${j}].fineDescription`}
                      on:blur={handleChange}
                      on:change={handleChange}
                      placeholder="Enter Fine Description"
                      type="textarea"
                      disabled={disableSupervisorSection}
                    />
                    {#if $errors.violations[j] && $errors.violations[j]["fineDescription"]}
                      <small class="text-danger">
                        {$errors.violations[j]["fineDescription"]}
                      </small>
                    {/if}
                  </FormGroup>
                </div>
              </fieldset>
              <div class="col-12">
                <!-- Attachment -->
                <fieldset>
                  <legend
                    >Fine Attachments
                    <button
                      type="button"
                      class="btn btn-light btn-sm"
                      on:click={() => {
                        document
                          .getElementById(
                            `fileViolationSupervisor${j}`
                          )
                          .click();
                      }}>Upload</button
                    >
                    <input
                      style="display:none"
                      type="file"
                      on:change={upLoadAttachment(j, GROUPFILES.FINE)}
                      id={`fileViolationSupervisor${j}`}
                    />
                    <button
                      type="button"
                      class="btn btn-light btn-sm"
                      on:click={() => showAddLinkModal(j, GROUPFILES.FINE)}
                      >Add Link</button
                    >
                  </legend>
                  <div class="row">
                    <div class="col-12">
                      {#if $form.violations[j].attachments.filter((s) => s.groupFile === GROUPFILES.FINE).length > 0}
                        {#each $form.violations[j].attachments as file, fileIdx}
                          {#if file.groupFile === GROUPFILES.FINE}
                            {#if file.type === "Link"}
                              <div class="d-flex">
                                <Input
                                  bind:value={$form.violations[j].attachments[
                                    fileIdx
                                  ].url}
                                  name={`violations[${j}].attachments[${fileIdx}].url`}
                                  on:blur={handleChange}
                                  on:change={handleChange}
                                  placeholder="Enter URL"
                                  readonly
                                  type="url"
                                />
                                <button
                                  class="btn btn-sm btn-primary ms-1"
                                  type="button"
                                  on:click={() =>
                                    changeLink(
                                      j,
                                      GROUPFILES.FINE,
                                      fileIdx,
                                      $form.violations[j].attachments[fileIdx]
                                        .url
                                    )}><i class="fas fa-pencil-alt" /></button
                                >
                                <button
                                  class="btn btn-sm btn-info ms-1"
                                  type="button"
                                  on:click={() =>
                                    openLink(
                                      $form.violations[j].attachments[fileIdx]
                                        .url
                                    )}
                                  ><i
                                    class="fas fa-external-link-alt"
                                  /></button
                                >
                                <button
                                  class="btn btn-sm btn-danger ms-1"
                                  type="button"
                                  on:click={() =>
                                    removeAttachmentLink(
                                      j,
                                      file.id,
                                      GROUPFILES.FINE
                                    )}>&times;</button
                                >
                              </div>
                            {:else}
                              <div class="attachment-pseudolink-wrapper">
                                {#if file.status === READY_TO_DOWNLOAD}
                                  <span
                                    class="attachment-pseudolink"
                                    on:click={() => {
                                      downloadAttachmentWithProgress(
                                        file.id,
                                        file.url
                                      );
                                    }}
                                  >
                                    <Spinner
                                      loading={downloadingAttachments.includes(
                                        file.id
                                      )}
                                    />
                                    {formatUrlForFileDownload(file.url)}
                                  </span>
                                {:else if file.status === UPLOADED}
                                  <span
                                    class="attachment-pseudolink ready-to-upload"
                                    >{formatUrlForFileDownload(file.url)}</span
                                  >
                                {:else if file.status === READY_TO_UPLOAD}
                                  <span
                                    class="attachment-pseudolink ready-to-upload"
                                    >{formatUrlForFileDownload(file.url)}</span
                                  >
                                  <small
                                    >this file is not saved with record until
                                    violation record is saved</small
                                  >
                                {/if}
                                <button
                                  class="btn btn-sm btn-danger ms-1"
                                  type="button"
                                  on:click={() =>
                                    removeFileLink(j, file.id, GROUPFILES.FINE)}
                                  >&times;</button
                                >
                              </div>
                            {/if}
                          {/if}
                        {/each}
                      {/if}
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </fieldset>
        {/if}
      </fieldset>
    {/each}

    {#if showAppealSection}
      <fieldset>
        <legend>Player Appeal Process</legend>
        <fieldset disabled={disableAll && recordStatus !== COMPLETE}>
          {#each $form.violations as violation, j}
            <div class="row">
              <div class="col-6">
                <FormGroup>
                  <Label for={`violationPostFineAmount${j}`}
                    >Post Appeal Fine Amount #{j + 1}</Label
                  >
                  <Input
                    bind:value={$form.violations[j].postFineAmount}
                    id={`violationPostFineAmount${j}`}
                    name={`violations[${j}].postFineAmount`}
                    on:blur={handleChange}
                    on:change={handleChange}
                    placeholder="Enter Post Appeal Fine Amount"
                    disabled={disableAppealSection}
                  />
                  {#if $errors.violations[j] && $errors.violations[j]["postFineAmount"]}
                    <small class="text-danger">
                      {$errors.violations[j]["postFineAmount"]}
                    </small>
                  {/if}
                </FormGroup>
              </div>
              <div class="col-6">
                <FormGroup>
                  <Label>Fine Amount #{j + 1}</Label>
                  <Input
                    bind:value={$form.violations[j].fineAmount}
                    name={`violations[${j}].fineAmount`}
                    on:blur={handleChange}
                    on:change={handleChange}
                    placeholder="Enter Fine Amount"
                    disabled
                  />
                  {#if $errors.violations[j] && $errors.violations[j]["fineAmount"]}
                    <small class="text-danger">
                      {$errors.violations[j]["fineAmount"]}
                    </small>
                  {/if}
                </FormGroup>
              </div>
            </div>
          {/each}
          <div class="row">
            <div class="col-12">
              <FormGroup>
                <Label for={`appealNotes${recordId}`}>Appeal Notes</Label>
                <Input
                  bind:value={$form.appealNotes}
                  id={`appealNotes${recordId}`}
                  name="appealNotes"
                  on:change={handleChange}
                  placeholder="Appeal Notes"
                  type="textarea"
                  disabled={disableAppealSection}
                />
                <FormError error={$errors.appealNotes} name="appealNotes" />
              </FormGroup>
            </div>
          </div>
        </fieldset>
        <div class="row">
          <div class="col-12">
            <!-- Attachment -->
            <fieldset>
              <legend
                >Appeal Attachments
                <button
                  type="button"
                  class="btn btn-light btn-sm"
                  on:click={() => {
                    document.getElementById(`fileAppeal${recordId}`).click();
                  }}>Upload</button
                >
                <input
                  style="display:none"
                  type="file"
                  on:change={upLoadAttachment(-1, GROUPFILES.APPEAL)}
                  id={`fileAppeal${recordId}`}
                />
                <button
                  type="button"
                  class="btn btn-light btn-sm"
                  on:click={() => showAddLinkModal(-1, GROUPFILES.APPEAL)}
                  >Add Link</button
                >
              </legend>
              <div class="row">
                <div class="col-12">
                  {#if $form.attachments.filter((s) => s.groupFile === GROUPFILES.APPEAL).length > 0}
                    {#each $form.attachments.filter((s) => s.groupFile === GROUPFILES.APPEAL) as file, fileIdx}
                      {#if file.type === "Link"}
                        <div class="d-flex">
                          <Input
                            bind:value={$form.attachments[fileIdx].url}
                            name={`attachments[${fileIdx}].url`}
                            on:blur={handleChange}
                            on:change={handleChange}
                            placeholder="Enter URL"
                            readonly
                            type="url"
                          />
                          <button
                            class="btn btn-sm btn-primary ms-1"
                            type="button"
                            on:click={() =>
                              changeLink(
                                -1,
                                GROUPFILES.APPEAL,
                                fileIdx,
                                $form.attachments[fileIdx].url
                              )}><i class="fas fa-pencil-alt" /></button
                          >
                          <button
                            class="btn btn-sm btn-info ms-1"
                            type="button"
                            on:click={() =>
                              openLink($form.attachments[fileIdx].url)}
                            ><i class="fas fa-external-link-alt" /></button
                          >
                          <button
                            class="btn btn-sm btn-danger ms-1"
                            type="button"
                            on:click={() =>
                              removeAttachmentLink(
                                -1,
                                file.id,
                                GROUPFILES.APPEAL
                              )}>&times;</button
                          >
                        </div>
                      {:else}
                        <div class="attachment-pseudolink-wrapper">
                          {#if file.status === READY_TO_DOWNLOAD}
                            <span
                              class="attachment-pseudolink"
                              on:click={() => {
                                downloadAttachmentWithProgress(
                                  file.id,
                                  file.url
                                );
                              }}
                            >
                              <Spinner
                                loading={downloadingAttachments.includes(
                                  file.id
                                )}
                              />
                              {formatUrlForFileDownload(file.url)}
                            </span>
                          {:else if file.status === UPLOADED}
                            <span class="attachment-pseudolink ready-to-upload"
                              >{formatUrlForFileDownload(file.url)}</span
                            >
                          {:else if file.status === READY_TO_UPLOAD}
                            <span class="attachment-pseudolink ready-to-upload"
                              >{formatUrlForFileDownload(file.url)}</span
                            >
                            <small
                              >this file is not saved with record until
                              violation record is saved</small
                            >
                          {/if}
                          <button
                            class="btn btn-sm btn-danger ms-1"
                            type="button"
                            on:click={() =>
                              removeFileLink(-1, file.id, GROUPFILES.APPEAL)}
                            >&times;</button
                          >
                        </div>
                      {/if}
                    {/each}
                  {/if}
                </div>
              </div>
            </fieldset>
          </div>
        </div>
      </fieldset>
    {/if}
  </Form>
</div>
<Messages bind:open={openMessage} violationRecordId={selectedRecord?.id} />

<style>
  :global(div.multiselect) {
    margin: 0;
    min-height: 38px;
    background-color: white;
  }
  :global(div.multiselect.disabled) {
    background-color: #e9ecef;
  }
</style>
