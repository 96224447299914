<script lang="ts">
  /* GSFT dependencies */
  import { createEventDispatcher } from 'svelte';
  import {
    Modal,
    ModalBody,
    ModalHeader,
  } from 'sveltestrap';
  import type { GsUser } from '../../types/users';
  import { deleteUser } from "../../api/users";
  import { notifications } from "../../helpers/notifications";

  export let open = false;
  export let user: GsUser;

  let saving = false;
  const dispatch = createEventDispatcher();

  const toggle = () => {
    open = !open;
  };

  const deleteTheUser = async (id: number)=> {
    saving = true
    try {
      const result = await deleteUser(id);
      if (result === true) {
        notifications.success(`User deleted successfully`);
        dispatch('reloadUsers');
        toggle();
      } else {
        notifications.danger(
          'This user can not be deleted, they have been deactivated instead'
        );
        toggle();
      }
    } catch (err) {
      notifications.danger(
        `Error deleting user: ${err?.detail == undefined ? '' : err.detail}`
      );
    } finally {
      saving = false;
    }
  }

  const discard = ()=> {
    toggle();
  }

</script>

<Modal isOpen={open} size="md" {toggle} id="deleteUserModal">
  <ModalHeader {toggle}>
    {`Delete user "${user.firstName} ${user.lastName}"?`}
  </ModalHeader>
  <ModalBody>
    <div class="row">
      <div class="col text-center">
        <button type="button" class="btn btn-success" disabled={saving} on:click={() => deleteTheUser(user.id)}>
          Delete user
        </button>
        <button type="button" class="btn btn-light" on:click={discard} disabled={saving}>
          Do not delete
        </button>
      </div>
    </div>
  </ModalBody>
</Modal>

