<script type="ts">
  /* Global dependencies */
  import { fade } from "svelte/transition";

  /* Props */
  export let name;
  export let error;

  /* Component */
</script>

{#if error}
  <small class="text-danger" data-cy={name}>{error}</small>
{/if}
