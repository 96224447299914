<script lang="ts">
  /* Global dependencies */
  import { onMount, onDestroy } from "svelte";
  import { push } from "svelte-spa-router";
  import { Button, Form, FormGroup, Input, Label } from "sveltestrap";

  /* GSFT dependencies */
  import { token } from "../store/token";
  import { user } from "../store/user";
  import { login } from "../api/auth";
  import { notifications } from "../helpers/notifications";
  import Toast from "../components/Toast.svelte";
  import ButtonSpinner from "../components/ButtonSpinner.svelte";

  /* Component */
  onMount(async () => {
    document.body.classList.add("login");
  });
  onDestroy(async () => {
    document.body.classList.remove("login");
  });

  /* Service variables */
  let loading: boolean = false;
  let error: string = "";

  /* Form variables */
  let password: string = "";
  let email: string = "";

  const isFormEmpty = (email, password) => !email?.length || !password?.length;

  const handleSubmit = (ev) => {
    ev.preventDefault();
    const data = {
      email: email,
      password: password,
    };
    loading = true;
    login(data)
      .then((response) => {
        $token = response.token;
        $user = response.userInfo;
        if (response.isOneTimePassword) {
          push("/password?onetime=1");
        } else {
          push("/");
        }
      })
      .catch((error) => {
        notifications.danger(error);
      })
      .finally(() => {
        loading = false;
      });
  };
</script>

<section class="vh-100">
  <div class="container py-5 h-100">
    <div class="row d-flex justify-content-center align-items-center h-100">
      <div class="col-12 col-md-8 col-lg-6 col-xl-5">
        <div class="text-center">
          <img src="/images/usta-logo-white.png" alt="" class="mb-5" />
        </div>
        <div class="card shadow-2-strong">
          <div class="card-body p-5">
            <h3 class="text-center mb-5">Grand Slam Code Violation System</h3>
            <Form on:submit={handleSubmit}>
              <FormGroup floating label="Email">
                <Input
                  placeholder="Enter a value"
                  bind:value={email}
                  type="email"
                  id="email"
                />
              </FormGroup>
              <FormGroup floating label="Password" class="pb-4">
                <Input
                  placeholder="Enter a value"
                  type="password"
                  bind:value={password}
                  id="password"
                />
              </FormGroup>
              <Button
                id="submit"
                color="danger"
                size="lg"
                block
                disabled={loading || isFormEmpty(email, password)}><ButtonSpinner {loading} /> Login</Button
              >
            </Form>
            <div class="text-center pt-2">
              <a href="#/forgot">Forgot Password</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<Toast />

<style>
  :global(body.login) {
    background: #bf0000 url(/images/bg.jpg) 0 0 no-repeat;
    background-size: cover;
  }
  .card {
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 8px;
    border-width: 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.6);
  }
</style>
