<script type="ts">
  /* Global dependencies */
  import { createEventDispatcher } from "svelte";
  import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
  } from "sveltestrap";

  /* GSFT dependencies */

  /* Props */
  export let open;

  /* Component */
  const dispatch = createEventDispatcher();
  const toggle = () => {
    open = !open;
  };
  const proceed = () => {
    dispatch("proceed");
    toggle();
  };
  const cancel = () => {
    dispatch("cancel");
    toggle();
  };
</script>

<Modal isOpen={open} size="sm" {toggle}>
  <ModalHeader {toggle}>Confirmation</ModalHeader>
  <ModalBody class="text-center fs-5">Are you sure?</ModalBody>
  <ModalFooter>
    <Button on:click={cancel} class="me-auto">Close</Button>
    <Button color="danger" on:click={proceed}>Proceed</Button>
  </ModalFooter>
</Modal>
