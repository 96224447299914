import { sendRequest } from '../api.js';
export const getAllCourts = async () => {
    const response = await sendRequest('/api/courts/getCourts', 'GET');
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject('Something went wrong');
};
export const getGsCourts = async () => {
    const response = await sendRequest('/api/courts/getGsCourts', 'GET');
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject('Something went wrong');
};
export const saveCourt = async (data) => {
    const response = await sendRequest('/api/courts/saveCourt', 'POST', JSON.stringify(data));
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject(response);
};
export const deleteCourt = async (id) => {
    const response = await sendRequest('/api/courts/deleteCourt', 'DELETE', JSON.stringify({ id }));
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject(response);
};
export const addCourtToGs = async (data) => {
    const response = await sendRequest('/api/courts/addCourtToGs', 'PUT', JSON.stringify(data));
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject(response);
};
export const removeCourtFromGs = async (data) => {
    const response = await sendRequest('/api/courts/removeCourtFromGs', 'DELETE', JSON.stringify(data));
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject(response);
};
