<script lang="ts">
  /* Local dependencies */
  import Layout from '../components/Layout.svelte';
</script>

<Layout>
  <section>
    <h1 class="text-center pt-5">404</h1>
  </section>
</Layout>
