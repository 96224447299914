<script type="ts">
  /* Global dependencies */
  import { createEventDispatcher } from "svelte";
  import { createForm, Form } from "svelte-forms-lib";
  import {
    Button,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
  } from "sveltestrap";
  import { object, string } from "yup";
  
  /* GSFT dependencies */
  import type { ChairUmpire } from "../../types/violations";
  import FormError from "../violations/FormError.svelte";

  /* Props */
  export let open = false;
  export let chairUmpire = null;
  export let genders: Array<string>;
  export let nationalities: Array<string>;
  export let badges: Array<string>;

  /* Component */
  const dispatch = createEventDispatcher();
  const {
    form,
    errors,
    handleChange,
    handleSubmit,
  } = createForm({
    initialValues: {
      firstName: chairUmpire ? chairUmpire.firstName : "",
      lastName: chairUmpire ? chairUmpire.lastName : "",
      email: chairUmpire ? chairUmpire.email : "",
      gender: chairUmpire ? chairUmpire.gender : "",
      itfId: chairUmpire ? chairUmpire.itfId : "",
      nationality: chairUmpire ? chairUmpire.nationality : "",
      badge: chairUmpire ? chairUmpire.badge : "",
    },
    validationSchema: object().shape({
      firstName: string().required(),
      lastName: string().required(),
      email: string().email().required(),
      gender: string().required(),
      itfId: string().required(),
      nationality: string().required(),
      badge: string().required(),
    }),
    onSubmit: (values) => {
      const payload = {
        chairUmpire: { ...(values as ChairUmpire) },
      };
      if (chairUmpire) {
        payload.chairUmpire.id = chairUmpire.id;
      }
      dispatch("save", payload);
    },
  });

  const toggle = () => {
    open = !open;
  };
  const cancel = () => {
    toggle();
  };
</script>

<Modal isOpen={open} size="lg" {toggle}>
  <ModalHeader {toggle}>
    {#if chairUmpire}
      Edit Chair Umpire
    {:else}
      Add Chair Umpire
    {/if}
  </ModalHeader>
  <ModalBody class="fs-5">
    <Form on:submit={handleSubmit}>
      <div class="row">
        <div class="col-6">
          <FormGroup>
            <Label>First Name</Label>
            <Input
              bind:value={$form.firstName}
              name="firstName"
              on:change={handleChange}
              type="text"
              required
            />
            <FormError error={$errors.firstName} name="firstName" />
          </FormGroup>
        </div>
        <div class="col-6">
          <FormGroup>
            <Label>Last Name</Label>
            <Input
              bind:value={$form.lastName}
              name="lastName"
              on:change={handleChange}
              type="text"
              required
            />
            <FormError error={$errors.lastName} name="lastName" />
          </FormGroup>
        </div>
        <div class="col-12">
          <FormGroup>
            <Label>Email</Label>
            <Input
              bind:value={$form.email}
              name="email"
              on:change={handleChange}
              type="email"
              required
            />
            <FormError error={$errors.email} name="email" />
          </FormGroup>
        </div>
        <div class="col-6">
          <Label>Gender</Label>
          <FormGroup>
            <Input
              bind:group={$form.gender}
              name="gender"
              on:change={handleChange}
              type="select"
              value={$form.gender}
              required
            >
              {#if genders}
                {#each genders as gender}
                  <option value={gender}>{gender}</option>
                {/each}
              {/if}
            </Input>
            <FormError error={$errors.gender} name="gender" />
          </FormGroup>
        </div>
        <div class="col-6">
          <FormGroup>
            <Label>Chair Id</Label>
            <Input
              bind:value={$form.itfId}
              name="itfId"
              on:change={handleChange}
              type="text"
              required
            />
            <FormError error={$errors.itfId} name="itfId" />
          </FormGroup>
        </div>
        <div class="col-6">
          <Label>Nationality</Label>
          <FormGroup>
            <Input
              bind:group={$form.nationality}
              name="nationality"
              on:change={handleChange}
              type="select"
              value={$form.nationality}
              required
            >
              {#if nationalities}
                {#each nationalities as nationality}
                  <option value={nationality}>{nationality}</option>
                {/each}
              {/if}
            </Input>
            <FormError error={$errors.nationality} name="nationality" />
          </FormGroup>
        </div>
        <div class="col-6">
          <Label>Badge</Label>
          <FormGroup>
            <Input
              bind:group={$form.badge}
              name="badge"
              on:change={handleChange}
              type="select"
              value={$form.badge}
              required
            >
              {#if badges}
                {#each badges as badge}
                  <option value={badge}>{badge}</option>
                {/each}
              {/if}
            </Input>
            <FormError error={$errors.badge} name="badge" />
          </FormGroup>
        </div>
      </div>
    </Form>
  </ModalBody>
  <ModalFooter>
    <Button on:click={cancel} class="me-auto">Close</Button>
    <Button type="submit" color="success" on:click={handleSubmit}>
      {#if chairUmpire}
        Save
      {:else}
        Add
      {/if}
    </Button>
  </ModalFooter>
</Modal>
