<script type="ts">
  /* Global dependencies */
  import { createEventDispatcher } from "svelte";
  import { createForm } from "svelte-forms-lib";
  import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Form,
    FormGroup,
    Input,
    Label,
  } from "sveltestrap";
  import { array, number, object, string } from "yup";
  import FormError from "./FormError.svelte";

  /* GSFT dependencies */

  /* Props */
  export let open;
  export let link;

  /* Component */
  let url;
  const dispatch = createEventDispatcher();

  const {
    form,
    errors,
    state,
    handleChange,
    updateValidateField,
    handleSubmit,
    handleReset,
    touched,
    isValid,
  } = createForm({
    initialValues: {
      url: link ? link : "",
    },
    validationSchema: object().shape({
      /* Base fields */
      url: string()
        .matches(
          /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/,
          "Enter correct url"
        )
        .required("URL is required"),
    }),
    onSubmit: (values) => {
      dispatch("proceed", { url: values.url });
    },
  });

  const toggle = () => {
    open = !open;
  };
  const proceed = () => {
    dispatch("proceed", { url });
  };
  const cancel = () => {
    dispatch("cancel");
  };
</script>

<Modal isOpen={open} size="md" {toggle}>
  <ModalHeader {toggle}>Add Link</ModalHeader>
  <ModalBody class="fs-5">
    <Form on:submit={handleSubmit} id="addAttachmentLink">
      <FormGroup>
        <Label>URL</Label>
        <Input
          bind:value={$form.url}
          name="url"
          on:change={handleChange}
          placeholder="Example: https://google.com"
          type="url"
          required
        />
        <FormError error={$errors.url} name="url" />
      </FormGroup>
    </Form>
  </ModalBody>
  <ModalFooter>
    <Button on:click={cancel} class="me-auto">Close</Button>
    <Button type="submit" color="danger" disabled={!$isValid} form="addAttachmentLink">Proceed</Button>
  </ModalFooter>
</Modal>
