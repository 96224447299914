<script type="ts">
  /* Props */
  export let doubleHeader = false;
  export let style = "";
</script>
<div class={`column-content ${doubleHeader ? 'double-header' : ''}`} style="{style || undefined}">
  <slot />
</div>

<style>
  .column-content {
    height: 30px;
    overflow: auto;
    height: calc(100vh - 150px);
  }
  .column-content.double-header {
    height: calc(100vh - 166px);
  }
</style>
