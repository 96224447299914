import { sendRequest } from '../api.js';
export const getActiveGrandSlam = async () => {
    const response = await sendRequest('/api/grandSlams/getActiveGrandSlam', 'GET');
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject('Something went wrong');
};
export const saveGrandSlam = async (data) => {
    const response = await sendRequest('/api/grandSlams/saveGrandSlam', 'POST', JSON.stringify(data));
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject('Something went wrong');
};
export const activateGrandSlam = async (GrandSlamName) => {
    const response = await sendRequest(`/api/grandSlams/activateGrandSlam`, 'POST', JSON.stringify({ GrandSlamName }));
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject('Something went wrong');
};
