<script type="ts">
  /* Global dependencies */
  import { createEventDispatcher } from "svelte";
  import {
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
  } from "sveltestrap";

  /* GSFT dependencies */
  import { createDraftViolation } from "../../api/violations";
  import type { ChairUmpire } from "../../types/violations";
  import type { GetDictionariesResult } from "../../types/dictionaries";
  import type { GsCourt } from "../../types/courts";
  import RecordForm from "./RecordForm.svelte";
  import { notifications } from "../../helpers/notifications";

  /* Props */
  export let chairUmpires: Array<ChairUmpire>;
  export let courts: Array<GsCourt>;
  export let dictionaries: GetDictionariesResult;

  export let open;

  /* Component */
  const dispatch = createEventDispatcher();

  const toggle = () => {
    open = !open;
  };

  const submitViolation = (event: CustomEvent) => {
    createDraftViolation(event.detail.data)
      .then((result) => {
        notifications.success("Case was created");

        dispatch("success", {
          data: result,
        });
        open = false;
      })
      .catch((responseErrors) => {
        if (Array.isArray(responseErrors) && responseErrors.length > 0) {
          notifications.danger(
            "Something went wrong: " + responseErrors.join(", ")
          );
        } else {
          notifications.danger("Something went wrong");
        }
      });
  };
</script>

<Modal isOpen={open} size="xl" {toggle} id="createViolationRecordModal">
  <ModalHeader {toggle}>Create Case</ModalHeader>
  <ModalBody>
    <RecordForm
      selectedRecord={null}
      {courts}
      {chairUmpires}
      {dictionaries}
      on:submit={submitViolation}
    />
  </ModalBody>
  <ModalFooter>
    <Button on:click={toggle} class="me-auto">Close</Button>
    <Button form="newRecordForm" color="primary">Submit</Button>
  </ModalFooter>
</Modal>
