<script lang="ts">
  /* Global dependencies */
  import {onMount } from "svelte";

  /* GSFT dependencies */
  import AddEditCourtModal from "../components/courts/AddEditCourtModal.svelte";
  import Layout from "../components/Layout.svelte";
  import type { GsCourt } from '../types/courts';
  import { getAllCourts, deleteCourt, getGsCourts } from "../api/courts";
  import { notifications } from "../helpers/notifications";
  import ConfirmationModal from "../components/ConfirmationModal.svelte";

  /* Props */
  let courts: Array<GsCourt> = [];
  let deleteIndex = -1;
  let clickedRow = -1;
  let openConfirmDelte = false;

  let currentAction = null;
  let actionBoundCourt = null;
  let mustOpenAddEditCourtModal = false;

  /* Component */
  onMount(async () => {
    await loadCourts();
  });

  const loadCourts = async () => {
    const gsCourtsById = (await getGsCourts()).reduce((result, court) => {
      result[court.id] = court;
      return result;
    }, {});

    const allCourts = await getAllCourts();
    courts = allCourts.map((court) => ({
      ...court,
      isIncludedInCurrentGs: !!gsCourtsById[court.id],
      grandSlamCourtId: gsCourtsById[court.id]?.grandSlamCourtId || '',
    }));
  }

  const removeCourt = (index)=>{
    openConfirmDelte =  true;
    deleteIndex = index;
  }

const removeCourtConfirmed = ()=> {
  deleteCourt(courts[deleteIndex].id).then((userResult) => {
      courts = courts.filter((e)=> e.id != courts[deleteIndex].id)
      }).catch((err)=>{
        notifications.danger(
            `Error deleting court. ${err?.detail == undefined ? '' : err.detail}`
          );
      });
}

const handleAddCourtButtonClick = () => {
  currentAction = "ADD";
  mustOpenAddEditCourtModal = true;
}

const handleCloseActionDialog = () => {
  currentAction = null;
  mustOpenAddEditCourtModal = false;
  actionBoundCourt = null;
}

const handleEditCourtButtonClick = (index) => {
  const court = courts[index];
  actionBoundCourt = {...court};
  currentAction = "EDIT";
  mustOpenAddEditCourtModal = true;
}

</script>

<Layout>

  <div class="row mt-2">
    <div class="col-12">
      <button on:click="{handleAddCourtButtonClick}">Add&nbsp;New&nbsp;Court</button>      
    </div>
  </div>

  <div class="">
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <!-- <th scope="col" class="col-status">#</th> -->
          <th scope="col" class="col-status">Court ID</th>
          <th scope="col" class="col-status">Grand Slam Court ID</th>
          <th scope="col" class="col-status">Court Name</th>
          <th scope="col" class="col-status">Is Active</th>
          <th scope="col" class="col-title">Actions</th>
        </tr>
      </thead>
      <tbody>
        {#if courts && courts.length > 0}
          {#each courts  as court, i}
            <tr>
              <td>{court.id}</td>
              <td>{court.grandSlamCourtId}</td>
              <td>{court.name}</td>
              <td>
                <input type="checkbox"  checked="{court.active}" disabled />
              </td>
              <td>
                <button class="sm" on:click="{()=> { handleEditCourtButtonClick(i) }}" disabled="{clickedRow !== -1 }"><i class="fas fa-edit"/></button>
                <button class="sm" on:click="{()=> { removeCourt(i) }}" disabled="{clickedRow !== -1 }"><i class="fas fa-trash"/></button>
              </td>
            </tr>
          {/each}
        {:else}
          <tr>
            <td class="text-center" colspan="6">No courts found</td>
          </tr>
        {/if}
      </tbody>
    </table>
  </div>

  {#key actionBoundCourt?.id}
  <AddEditCourtModal bind:open={mustOpenAddEditCourtModal} bind:court={actionBoundCourt} bind:action={currentAction}  on:reloadCourts={loadCourts} on:close={handleCloseActionDialog} />
  {/key}

  <ConfirmationModal
    bind:open={openConfirmDelte}
    on:proceed={() => removeCourtConfirmed()}
    on:cancel={() => (openConfirmDelte =! openConfirmDelte)}
  />
</Layout>
