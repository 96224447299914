<script type="ts">
  /* Props */
  export let style = "";
</script>

<div class="column-header" style="{style || undefined}">
  <slot />
</div>

<style>
  .column-header {
    background: #eee;
    border-bottom: 1px solid var(--gsft-border-color);
    box-shadow: 0 5px 15px #eee;
    font-size: 1.1em;
    padding: 15px 10px;
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
    vertical-align: middle;
  }
  :global(.column-header button) {
    margin-bottom: 0;
  }
</style>
