<script lang="ts">
  /* Local dependencies */
  import Layout from "../components/Layout.svelte";
</script>

<svelte:head>
  <script
    type="text/javascript"
    src="https://analytics.usta.com/javascripts/api/viz_v1.js"></script>
</svelte:head>

<Layout>
  <section>
    <h1 class="text-center pt-4">Analytics</h1>
  </section>
  <div class="analytics-container">
    <div class="tableauPlaceholder" style="width: 1366px; height: 1195px;">
      <object
        class="tableauViz"
        width="1366"
        height="1195"
        style="display:none;"
        title="Analytics"
      >
        <param name="host_url" value="https%3A%2F%2Fanalytics.usta.com%2F" />
        <param name="embed_code_version" value="3" />
        <param name="site_root" value="/t/usopen_enterprise" />
        <param name="name" value="GSFTS-CodeViolationDashboard/GSFTSDatabase" />
        <param name="refresh" value="yes" />
        <param name="tabs" value="no" />
        <param name="toolbar" value="no" />
        <param name="showAppBanner" value="false" />
      </object>
    </div>
  </div>
</Layout>

<style>
  .analytics-container {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    min-width: 1366px;
  }

  .tableauPlaceholder {
    width: 1366px;
    height: 1168px;
    margin-bottom: 48px;
    margin-top: 24px;
  }
</style>
