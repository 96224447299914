import { sendRequest } from '../api.js';
export const login = async (data) => {
    const response = await sendRequest('/api/auth/login', 'POST', JSON.stringify(data), null);
    if (response.ok) {
        return (await response.json());
    }
    else if (response.status === 403) {
        return Promise.reject('User is not added in Active Grand Slam');
    }
    else if (response.status === 409) {
        return response;
    }
    return Promise.reject('Provided credentials do not match');
};
export const me = async () => {
    const response = await sendRequest('/api/auth/me', 'GET', null, null);
    if (response.ok) {
        return await response.json();
    }
    else {
        return Promise.reject('Something went wrong');
    }
};
export const resetPassword = async (email) => {
    const response = await sendRequest('/api/auth/resetPassword', 'POST', JSON.stringify({ email }), null);
    if (response.ok) {
        return await response.json();
    }
    else if (response.status === 404) {
        return Promise.reject("That email was not found");
    }
    else {
        return Promise.reject('Something went wrong');
    }
};
