import { API_URL } from './config.js';
import { token } from './store/token';
import { push } from 'svelte-spa-router';
let auth;
token.subscribe((value) => {
    auth = value;
});
export const sendRequest = async (url, method, params = null, notJson = false) => {
    const headers = {
        Authorization: `Bearer ${auth}`,
    };
    if (!notJson) {
        headers['Content-Type'] = 'application/json';
    }
    return fetch(`${API_URL}${url}`, {
        method,
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers,
        redirect: 'follow',
        referrerPolicy: 'no-referrer',
        body: params,
    })
        .then((response) => {
        if (response.ok) {
            return response;
        }
        else if (response.status === 409 || response.status === 404) {
            return response;
        }
        else if (response.status === 401) {
            push('/logout');
        }
        return response.json().then((res) => Promise.reject(res));
    })
        .catch((error) => {
        console.error(error, error.status);
        if (error.status === 401) {
            push('/logout');
        }
        return error;
    });
};
export const sendGetRequest = (url, params) => {
    return sendRequest(url, 'GET');
};
export const sendPostRequest = (url, data) => {
    return sendRequest(url, 'GET', data);
};
export const downloadRequest = async (uri) => {
    const headers = {
        Authorization: `Bearer ${auth}`,
    };
    return fetch(`${API_URL}${uri}`, { headers }).then((response) => response.blob());
};
