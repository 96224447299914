import { wrap } from 'svelte-spa-router/wrap';
import { user } from './store/token.js';
import Home from './pages/Home.svelte';
import Violations from './pages/Violations.svelte';
import Settings from './pages/Settings.svelte';
import Users from './pages/Users.svelte';
import Courts from './pages/Courts.svelte';
import Login from './pages/Login.svelte';
import Logout from './pages/Logout.svelte';
import NotFound from './pages/NotFound.svelte';
import ForgotPassword from './pages/ForgotPassword.svelte';
import ChangePassword from './pages/ChangePassword.svelte';
import Analytics from './pages/Analytics.svelte';
let auth;
user.subscribe((value) => {
    auth = value;
});
const checkAuth = (detail) => {
    if (auth) {
        return true;
    }
    else {
        return false;
    }
};
export default {
    '/': wrap({
        component: Home,
        conditions: [(detail) => checkAuth(detail)],
    }),
    '/violations/:filter/:id?': wrap({
        component: Violations,
        conditions: [(detail) => checkAuth(detail)],
    }),
    '/settings': wrap({
        component: Settings,
        conditions: [(detail) => checkAuth(detail)],
    }),
    '/users': wrap({
        component: Users,
        conditions: [(detail) => checkAuth(detail)],
    }),
    '/courts': wrap({
        component: Courts,
        conditions: [(detail) => checkAuth(detail)],
    }),
    '/password': wrap({
        component: ChangePassword,
        conditions: [(detail) => checkAuth(detail)],
    }),
    '/login': wrap({
        component: Login,
    }),
    "/logout": wrap({
        component: Logout,
    }),
    '/forgot': wrap({
        component: ForgotPassword,
    }),
    '/analytics': wrap({
        component: Analytics,
    }),
    '*': wrap({
        component: NotFound,
        conditions: [(detail) => checkAuth(detail)],
    }),
};
