<script lang="ts">
  /* Global dependencies */
  import { onDestroy, onMount } from "svelte";
  import Skeleton from "svelte-skeleton/Skeleton.svelte";
  import { push } from "svelte-spa-router";
  import { Button, Offcanvas } from "sveltestrap";

  /* GSFT dependencies */
  import type { GetDictionariesResult } from "../types/dictionaries";
  import type { GrandSlam } from "../types/grand-slams";
  import type { ChairUmpire } from "../types/violations";

  import { activeGrandSlam as activeGrandSlamStore } from "../store/active-grand-slam";
  import { dictionaries as dictionariesStore } from "../store/dictionaries";
  import { isChairUmpire, isGrandSlamSupervisor } from "../store/user";

  import { getChairUmpires, getGsChairUmpires } from "../api/chair-umpires";
  import { activateGrandSlam, saveGrandSlam } from "../api/grand-slams";

  import ConfirmationModal from "../components/ConfirmationModal.svelte";
  import Layout from "../components/Layout.svelte";
  import ChairUmpiresForm from "../components/settings/ChairUmpiresForm.svelte";
  import SettingsForm from "../components/settings/SettingsForm.svelte";

  import { notifications } from "../helpers/notifications";

  /* Component */
  let saving = false;
  let activeGrandSlamTimeStamp: number;
  let activeGrandSlam: GrandSlam;
  let activeGrandSlamName: string;
  let dictionaries: GetDictionariesResult;
  let candidatesChairUmpires: Array<ChairUmpire> = [];
  let gsChairUmpires: Array<ChairUmpire> = [];

  $: grandSlams = dictionaries ? dictionaries.grandSlamNames : [];
  $: genders = dictionaries ? dictionaries.genders : [];
  $: nationalities = dictionaries ? dictionaries.nationalities : [];
  $: badges = dictionaries ? dictionaries.badges : [];

  let openChangeGrandSlamCanvas = false;
  let openChangeGrandSlamConfirmation = false;
  const toggleCanvas = () =>
    (openChangeGrandSlamCanvas = !openChangeGrandSlamCanvas);

  const unsubscribe = activeGrandSlamStore.subscribe((gs: GrandSlam) => {
    if (gs) {
      activeGrandSlamTimeStamp = +new Date();
      activeGrandSlam = gs;
      activeGrandSlamName = gs.name;
    }
  });

  dictionariesStore.subscribe((dictionariesResult: GetDictionariesResult) => {
    if (dictionariesResult) {
      dictionaries = dictionariesResult;
    }
  });

  onMount(() => {
    if ($isChairUmpire || $isGrandSlamSupervisor) push("/violations/my");
    getUmpires();
  });

  onDestroy(unsubscribe);

  const saveGS = (event: CustomEvent) => {
    const { gs } = event.detail;
    if (gs) {
      saving = true;
      saveGrandSlam(gs)
        .then((resGs: GrandSlam) => {
          $activeGrandSlamStore = resGs;
          notifications.success("Grand Slam was updated");
        })
        .catch(() => {
          notifications.danger("Something went wrong");
        })
        .finally(() => (saving = false));
    }
  };

  const setActiveGrandSlam = () => {
    saving = true;
    activateGrandSlam(activeGrandSlamName)
      .then((resGs: GrandSlam) => {
        $activeGrandSlamStore = resGs;
        activeGrandSlamName = resGs.name;
        toggleCanvas();
        notifications.success("Grand Slam was activated");
      })
      .catch(() => {
        notifications.danger("Something went wrong");
      })
      .finally(() => (saving = false));
  };

  const getUmpires = () => {
    getChairUmpires().then((res) => {
      candidatesChairUmpires = res;
    });
    getGsChairUmpires().then((res) => {
      gsChairUmpires = res;
    });
  };
</script>

<Layout>
  <section>
    <h1 class="text-center mt-2 mb-4">Grand Slam Settings</h1>
    <div class="row">
      <div class="col-md-9 col-sm-12 order-md-1">
        {#if activeGrandSlam}
          {#key activeGrandSlamTimeStamp}
            <SettingsForm
              grandSlam={activeGrandSlam}
              {saving}
              on:saveGrandSlam={saveGS}
            />
          {/key}
        {:else}
          <Skeleton height={360} width={"100%"}>
            <rect width="15%" height="10" x="0" y="0" rx="5" ry="5" />
            <rect width="15%" height="10" x="40%" y="0" rx="5" ry="5" />

            <rect width="35%" height="40" x="0" y="20" rx="5" ry="5" />
            <rect width="55%" height="40" x="40%" y="20" rx="5" ry="5" />

            <rect width="15%" height="10" x="0" y="80" rx="5" ry="5" />
            <rect width="15%" height="10" x="40%" y="80" rx="5" ry="5" />

            <rect width="35%" height="40" x="0" y="100" rx="5" ry="5" />
            <rect width="55%" height="40" x="40%" y="100" rx="5" ry="5" />

            <rect width="15%" height="40" x="30%" y="160" rx="5" ry="5" />
            <rect width="15%" height="40" x="50%" y="160" rx="5" ry="5" />
          </Skeleton>
        {/if}
      </div>
      <div class="col-md-3 col-sm-12 order-md-0">
        <h5 class="text-center">
          Active Grand Slam:
          {#if activeGrandSlam}
            <div class="fs-2 py-2">{activeGrandSlam.name}</div>
          {:else}
            <Skeleton height={54} width={160}>
              <rect width="160" height="10" x="0" y="23" rx="5" ry="5" />
            </Skeleton>
          {/if}
        </h5>
        <div class="text-center">
          <Button color="danger" on:click={toggleCanvas}>Change</Button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <ChairUmpiresForm
          {activeGrandSlam}
          {nationalities}
          {genders}
          {badges}
          {candidatesChairUmpires}
          {gsChairUmpires}
          on:refreshUmpires={getUmpires}
        />
      </div>
    </div>
  </section>

  <Offcanvas
    header="Change Active Grand Slam"
    scroll
    isOpen={openChangeGrandSlamCanvas}
    toggle={toggleCanvas}
    backdrop={false}
  >
    <div class="row">
      <div class="col">
        <div class="mb-3">
          <label for="exampleTemplate" class="form-label"
            >Active Grand Slam</label
          >
          <select
            class="form-control"
            bind:value={activeGrandSlamName}
            on:change={() => (openChangeGrandSlamConfirmation = true)}
          >
            {#each grandSlams as gs}
              <option>{gs}</option>
            {/each}
          </select>
        </div>
        <div class="alert alert-info">
          The active grand slam controls the slam settings & theme of the system<br
          />
          Updating the active grand slam makes the below changes
          <ul>
            <li>Replace the grand slam settings</li>
            <li>Clears out the active list of chair umpires</li>
          </ul>
        </div>
      </div>
    </div>
  </Offcanvas>
  <ConfirmationModal
    bind:open={openChangeGrandSlamConfirmation}
    on:proceed={() => setActiveGrandSlam()}
    on:cancel={() => (activeGrandSlamName = activeGrandSlam.name)}
  />
</Layout>
