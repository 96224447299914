<script>
  /* Global dependencies */
  import { flip } from 'svelte/animate';
  import { fly } from 'svelte/transition';

  /* GSFT dependencies */
  import { notifications } from '../helpers/notifications';

  /* Props */
  export let themes = {
    danger: '#E26D69',
    success: '#84C991',
    warning: '#f0ad4e',
    info: '#5bc0de',
    default: '#aaaaaa',
  };
</script>

<div class="notifications">
  {#each $notifications as notification (notification.id)}
    <div
      animate:flip
      class="notifications-toast"
      style="background: {themes[notification.type]};"
      transition:fly={{ y: 30 }}
    >
      <div class="content">{notification.message}</div>
      {#if notification.icon}<i class={notification.icon} />{/if}
    </div>
  {/each}
</div>

<style>
  .notifications {
    align-items: center;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: flex-start; */
    margin: 0 auto;
    padding: 0;
    pointer-events: none;
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 9999;
  }

  .notifications-toast {
    border-radius: 4px;
    display: block;
    flex: 0 0 auto;
    margin-bottom: 10px;
    width: 300px;
  }

  .content {
    color: white;
    display: block;
    font-weight: 500;
    padding: 10px;
  }
</style>
