<script lang="ts">
  /* Global dependencies */
  import { createEventDispatcher, onDestroy } from 'svelte';
  import { createForm } from "svelte-forms-lib";
  import { boolean, object, string } from "yup";
  import {
    Form,
    Input,
    Modal,
    ModalBody,
    ModalHeader,
    FormGroup,
    Label
  } from 'sveltestrap';
  
  import { saveCourt, addCourtToGs, removeCourtFromGs } from "../../api/courts";
  import { notifications } from "../../helpers/notifications";
  import { activeGrandSlam as activeGrandSlamStore  } from "../../store/active-grand-slam";
  import type { GrandSlam } from "../../types/grand-slams";
  import type { GsCourt } from '../../types/courts';
  import FormError from "../violations/FormError.svelte";


  /* Props */
  export let court: GsCourt;
  export let open;
  export let action: string;
  let activeGrandSlam: GrandSlam;
  let saving = false;
  const dispatch = createEventDispatcher();

  const unsubscribe = activeGrandSlamStore.subscribe((gs: GrandSlam) => {
    if (gs) {
      activeGrandSlam = gs;
    }
  });

  const toggle = () => {
    const nextOpen = !open;
    if (!nextOpen) {
      dispatch('close');
    }
    open = !open;
  };

  const createOrUpdateCourt = async (newValues: GsCourt, originalValues: GsCourt, handleReset) => {
    saving = true;

    const courtPayload: any = {...newValues};

    if (courtPayload.grandSlamCourtId && !originalValues.active) {
      courtPayload.active = true;
    }
    if (originalValues?.id) {
      courtPayload.oldId = originalValues.id;
    }
    
    try {
      const wasCourtSaved = await saveCourt(courtPayload);
      if (originalValues.grandSlamCourtId !== newValues.grandSlamCourtId) { 
        if (courtPayload.grandSlamCourtId) {
          if (originalValues.grandSlamCourtId) {
            // if there already is a grand slam court ID set, remove it first to update it
            const toggleCourtPayload = { courtId: courtPayload.id, grandSlamId: activeGrandSlam.id };
            await removeCourtFromGs(toggleCourtPayload);  
          }
          const toggleCourtPayload: any = { courtId: courtPayload.id, gsCourtId: courtPayload.grandSlamCourtId };
          await addCourtToGs(toggleCourtPayload);
        } else {
          const toggleCourtPayload = { courtId: courtPayload.id, grandSlamId: activeGrandSlam.id };
          await removeCourtFromGs(toggleCourtPayload);
        };
      }
      saving = false;
      dispatch('reloadCourts');
      toggle();
      if (handleReset) {
        handleReset();
      }
    } catch (err) {
      saving =false;
      notifications.danger(
        `Error saving court. ${err?.detail == undefined ? '' : err.detail}`
      );
    }
  }

  const discard = (handleReset)=> {
    toggle();
    if (handleReset) {
      handleReset();
    }
  }

  $: originalCourtValues = {...court};

  onDestroy(unsubscribe);

  const {
    form,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
  } = createForm({
    initialValues: {
      id: court?.id || "",
      name: court?.name || "",
      grandSlamCourtId: court?.grandSlamCourtId || "",
      active: court?.active || false,
    },
    validationSchema: object().shape({
      id: string().required(),
      name: string().required(),
      grandSlamCourtId: string(),
      active: boolean(),
    }),
    onSubmit: (values) => {
      createOrUpdateCourt({...values, isIncludedInCurrentGs: !!values.grandSlamCourtId}, originalCourtValues, handleReset);
    },
  });

</script>

<Modal isOpen={open} size="md" {toggle} id="addEditCourtModal">
  <ModalHeader toggle={() => {handleReset(); toggle();}}>{action === "ADD" ? "Add New Court" : "Edit Court Properties"}</ModalHeader>
  <ModalBody>
      <Form on:submit={handleSubmit}>
      <div class="row">
        <div class="col-6">
          <FormGroup>
            <Label for="id">Court ID (*)</Label>
            <Input
              bind:value={$form.id}
              on:change={handleChange}
              name="id"
              type="text"
              required
              
            />
            <FormError error={$errors.id} name="id" />
          </FormGroup>
        </div>

        <div class="col-6">
          <FormGroup>
            <Label for="name">Court Name (*)</Label>
            <Input
              bind:value={$form.name}
              on:change={handleChange}
              name="name"
              type="text"
              required
            />
            <FormError error={$errors.name} name="name" />
          </FormGroup>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <FormGroup style="display:flex;flex-flow:row nowrap;">
            <Input
              bind:checked={$form.active}
              on:change={handleChange}
              name="active"
              type="checkbox"
              id="active"
            />
            <Label for="active" style="cursor:pointer;">Is Active</Label>
            <FormError error={$errors.active} name="active" />
          </FormGroup>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <p style="background-color:#eee;padding:6px 18px;font-size:small;">
            Add Grand Slam Court ID to add the court to current Grand Slam. To remove the court from the slam, delete Grand Slam Court ID.
          </p>
          <FormGroup>
            <Label for="name">Grand Slam Court ID</Label>
            <Input
              bind:value={$form.grandSlamCourtId}
              on:change={handleChange}
              name="grandSlamCourtId"
              type="text"
            />
            <FormError error={$errors.grandSlamCourtId} name="grandSlamCourtId" />
          </FormGroup>
        </div>
      </div>
      <div class="row">
        <div class="col text-center">
          <button type="submit" class="btn btn-success" disabled={saving}>{action.toUpperCase() === "ADD" ? "Add new court" : "Save court properties"}</button>
          <button type="button" class="btn btn-light" on:click={() => discard(handleReset)}  disabled={saving}>Discard</button>
        </div>
      </div>
    </Form>
  </ModalBody>
</Modal>

