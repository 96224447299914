<script type="ts">
  /* Global dependencies */
  import { createEventDispatcher } from "svelte";
  import Flatpickr from "svelte-flatpickr";
  import { createForm } from "svelte-forms-lib";
  import { Form, FormGroup, Input, Label } from "sveltestrap";
  import { object, string } from "yup";

  /* GSFT dependencies */
  import type { GrandSlam } from "../../types/grand-slams";
  import Spinner from "../Spinner.svelte";
  import FormError from "../violations/FormError.svelte";

  /* Props */
  export let grandSlam: GrandSlam;
  export let saving = false;

  /* Component */
  const dispatch = createEventDispatcher();

  const {
    form,
    errors,
    state,
    handleChange,
    updateValidateField,
    handleSubmit,
    handleReset,
    touched,
    isValid,
  } = createForm({
    initialValues: {
      id: grandSlam.id,
      name: grandSlam.name,
      recordPrefix: grandSlam.recordPrefix,
      fineCurrency: grandSlam.fineCurrency,
      purserOfficeEmails: grandSlam.purserOfficeEmails,
      informationEmailsInCc: grandSlam.informationEmailsInCc ? grandSlam.informationEmailsInCc : "",
      reportSupervisorName: grandSlam.reportSupervisorName ? grandSlam.reportSupervisorName: "",
      reportSupervisorEmail: grandSlam.reportSupervisorEmail ? grandSlam.reportSupervisorEmail: "",
      startDate: grandSlam.startDate,
      endDate: grandSlam.endDate,
    },
    validationSchema: object().shape({
      id: string().required(),
      name: string().required(),
      recordPrefix: string().required("Record prefix is required"),
      fineCurrency: string().required("Currency is required"),
      purserOfficeEmails: string().required("Purser Office Emails is required"),
      informationEmailsInCc: string().required("Information Emails In Cc is required"),
      reportSupervisorName: string().required("Supervisor Name is required"),
      reportSupervisorEmail: string().required("Supervisor Email is required"),
      startDate: string().required("Start Date is required"),
      endDate: string().required("End Date is required"),
    }),
    onSubmit: (values) => {
      const newValues = { ...values };
      delete newValues.id;
      dispatch("saveGrandSlam", {
        gs: {
          id: `${values.id}`,
          ...newValues,
        },
      });
    },
  });
  const discard = () => {
    handleReset();
  };
</script>

<Form on:submit={handleSubmit}>
  <Spinner loading={saving} />
  <div class="row">
    <div class="col-4">
      <FormGroup>
        <Label>Violation Record Prefix</Label>
        <Input
          bind:value={$form.recordPrefix}
          name="recordPrefix"
          on:change={handleChange}
          type="text"
          required
          disabled
        />
        <FormError error={$errors.recordPrefix} name="recordPrefix" />
      </FormGroup>
    </div>
    <div class="col-4">
      <FormGroup>
        <Label>Purser’s Office Emails</Label>
        <Input
          bind:value={$form.purserOfficeEmails}
          name="purserOfficeEmails"
          on:change={handleChange}
          type="text"
          required
        />
        <FormError
          error={$errors.purserOfficeEmails}
          name="purserOfficeEmails"
        />
      </FormGroup>
    </div>
    <div class="col-4">
      <FormGroup>
        <Label>Supervisor Name</Label>
        <Input
          bind:value={$form.reportSupervisorName}
          name="reportSupervisorName"
          on:change={handleChange}
          type="text"
        />
        <FormError
          error={$errors.reportSupervisorName}
          name="reportSupervisorName"
        />
      </FormGroup>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <div class="mb-3">
        <FormGroup>
          <Label>Final Date</Label>
          <div class="input-group">
            <Flatpickr
              bind:formattedValue={$form.endDate}
              bind:value={$form.endDate}
              class="form-control form-flatpickr"
              name="endDate"
              required
            />
            <label for="endDate" class="input-group-text">
              <i class="fas fa-calendar text-dark" />
            </label>
          </div>
          <FormError error={$errors.endDate} name="endDate" />
        </FormGroup>
      </div>
    </div>
    <div class="col-4">
      <FormGroup>
        <Label>Informational Email(s) in CC</Label>
        <Input
          bind:value={$form.informationEmailsInCc}
          name="informationEmailsInCc"
          on:change={handleChange}
          type="text"
        />
        <FormError
          error={$errors.informationEmailsInCc}
          name="informationEmailsInCc"
        />
      </FormGroup>
    </div>
    <div class="col-4">
      <FormGroup>
        <Label>Supervisor Email</Label>
        <Input
          bind:value={$form.reportSupervisorEmail}
          name="reportSupervisorEmail"
          on:change={handleChange}
          type="text"
        />
        <FormError
          error={$errors.reportSupervisorEmail}
          name="reportSupervisorEmail"
        />
      </FormGroup>
    </div>  
    
  </div>
  <div class="row">
    <div class="col text-center">
      <button type="submit" class="btn btn-success">Save Changes</button>
      <button type="button" class="btn btn-light" on:click={discard}>Discard</button>
    </div>
  </div>
</Form>
