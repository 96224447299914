<script type="ts">
  /* GSFT dependencies */
  import Navbar from "./Navbar.svelte";
  import Toast from "./Toast.svelte";

  export let noPadding = false;
</script>

<Navbar />
<main class="container-fluid {noPadding ? 'px-0' : ''}">
  <slot />
</main>
<Toast />
