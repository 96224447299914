import { sendRequest, downloadRequest } from '../api.js';
export const getGsChairUmpires = async () => {
    const response = await sendRequest(`/api/chairUmpires/getGsChairUmpires`, 'GET');
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject('Something went wrong');
};
export const toggleActivateChairUmpire = async (itfId, doDeactivate) => {
    const endpoint = doDeactivate ? 'deactivateChairUmpire' : 'activateChairUmpire';
    const response = await sendRequest(`/api/chairUmpires/${endpoint}`, 'POST', JSON.stringify({ itfId }));
    if (response.ok) {
        return (await response.json());
    }
    else if (response && response.detail) {
        return Promise.reject(response.detail);
    }
    return Promise.reject('Unknown error');
};
export const getChairUmpires = async () => {
    const response = await sendRequest(`/api/chairUmpires/getChairUmpires`, 'GET');
    if (response.ok) {
        return (await response.json());
    }
    return Promise.reject('Something went wrong');
};
export const saveChairUmpire = async (data) => {
    const response = await sendRequest(`/api/chairUmpires/saveChairUmpire`, 'POST', JSON.stringify(data));
    if (response.ok) {
        return (await response.json());
    }
    else if (response && response.detail) {
        return Promise.reject(response.detail);
    }
    return Promise.reject('Unknown error');
};
export const deleteChairUmpire = async (itfId) => {
    const response = await sendRequest(`/api/chairUmpires/deleteChairUmpire`, 'DELETE', JSON.stringify({ itfId }));
    if (response.ok) {
        return (await response.json());
    }
    else if (response && response.detail) {
        return Promise.reject(response.detail);
    }
    return Promise.reject('Unknown error');
};
export const addChairUmpiresToGs = async (ids) => {
    const response = await sendRequest(`/api/chairUmpires/addChairUmpiresToGs`, 'PUT', JSON.stringify({ umpireIds: ids }));
    if (response.ok) {
        return (await response.json());
    }
    else if (response && response.detail) {
        return Promise.reject(response.detail);
    }
    return Promise.reject('Unknown error');
};
export const removeChairUmpiresToGs = async (ids) => {
    const response = await sendRequest(`/api/chairUmpires/removeChairUmpiresFromGs`, 'DELETE', JSON.stringify({ umpireIds: ids }));
    if (response.ok) {
        return (await response.json());
    }
    else if (response && response.detail) {
        return Promise.reject(response.detail);
    }
    return Promise.reject('Unknown error');
};
export const exportCandiateList = async (fileName) => {
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);
    downloadRequest(`/api/chairUmpires/exportCandiateList`).then((blobby) => {
        let objectUrl = window.URL.createObjectURL(blobby);
        anchor.href = objectUrl;
        anchor.download = `${fileName}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(objectUrl);
    });
};
export const importCandiateList = async (file) => {
    const response = await sendRequest(`/api/chairUmpires/importChairUmpires`, 'POST', file, true);
    if (response.ok) {
        return (await response.json());
    }
    else if (response && response.detail) {
        return Promise.reject(response.detail);
    }
    return Promise.reject('Unknown error');
};
