<script type="ts">
  /* Global dependencies */
  import { Nav, NavItem, NavLink } from "sveltestrap";

  import {
    isGrandSlamSupervisor,
    isSuperAdmin,
  } from "../../store/user";

  /* GSFT dependencies */
  import ColumnHeader from "./ColumnHeader.svelte";
  import ColumnContent from "./ColumnContent.svelte";

  $: isRecordLinkVisible = $isGrandSlamSupervisor || $isSuperAdmin;

  /* Components */
  export let params = { filter: "", id: null };
</script>

<ColumnHeader>Violations</ColumnHeader>

<ColumnContent>
  <Nav class="violations-nav flex-column pt-3">
    <NavItem>
      <NavLink href="#/violations/my" active={params.filter === "my"}
        >My Cases</NavLink
      >
    </NavItem>
    <NavItem>
      <NavLink href="#/violations/draft" active={params.filter === "draft"}
        >Draft Cases</NavLink
      >
    </NavItem>
    <NavItem>
      <NavLink href="#/violations/open" active={params.filter === "open"}
        >Open Cases</NavLink
      >
    </NavItem>
    <NavItem>
      <NavLink
        href="#/violations/complete"
        active={params.filter === "complete"}>Completed Cases</NavLink
      >
    </NavItem>
    <NavItem>
      <NavLink href="#/violations/closed" active={params.filter === "closed"}
        >Closed Cases</NavLink
      >
    </NavItem>
    {#if isRecordLinkVisible}
    <NavItem>
      <a target="_blank"
      class="report-link"
      href="#/analytics">
        Code Violation Report
        <div class="report-link-note">
          Will open in a new window
          <i class="fas fa-external-link-alt" />
        </div>
      </a>
    </NavItem>
    {/if}
  </Nav>
</ColumnContent>

<style>
  .report-link {
    padding: 0.5rem 1rem;
    display: block;
  }
  .report-link-note {
    font-size: small;
    opacity: .75;
  }
</style>