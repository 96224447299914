<script type="ts">
  /* Global dependencies */
  import { createEventDispatcher } from "svelte";
  import {
    Button,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
  } from "sveltestrap";

  /* GSFT dependencies */

  /* Props */
  export let open;
  export let firstPlayer;
  export let secondPlayer;
  export let codes;
  let pdfPlayer="";

  /* Component */
  const dispatch = createEventDispatcher();
  const toggle = () => {
    open = !open;
  };
  const proceed = () => {
    dispatch("proceed",{player: pdfPlayer});
    toggle();
    pdfPlayer="";
  };
  const cancel = () => {
    dispatch("cancel");
    toggle();
    pdfPlayer="";
  };
  const hasFirstPlayer = ()=>{
    return codes.indexOf(1) != -1 || codes.indexOf(3)!= -1;
  }
  const hasSecondPlayer = ()=>{
    return codes.indexOf(2) != -1 || codes.indexOf(3)!= -1;
  }
</script>

<Modal isOpen={open} {toggle}>
  <ModalHeader {toggle}>Print PDF - Select Doubles Player</ModalHeader>
  
  <ModalBody class="row">
    <div class="col-12">
      <FormGroup>
              
        <Input
              bind:group={pdfPlayer}
              label={"Full Report"}
              name="players"
              type="radio"
              value={""}
        />    
        
        {#if hasFirstPlayer() }  
        <Input
              bind:group={pdfPlayer}
              label={firstPlayer}
              name="players"
              type="radio"
              value={1}
        />
        {/if}
        
        {#if hasSecondPlayer() }
            <Input
              bind:group={pdfPlayer}
              label={secondPlayer}
              name="players"
              type="radio"
              value={2}
        />
        {/if}
      </FormGroup>
    </div>
  </ModalBody>
  <ModalFooter>
    <Button on:click={cancel} class="me-auto">Close</Button>
    <Button color="danger" on:click={proceed}>Print PDF</Button>
  </ModalFooter>
</Modal>