import { writable, derived } from 'svelte/store';
const storedToken = localStorage.getItem('token');
export const token = writable(storedToken);
export const user = derived(token, ($token) => $token ? parseJwt($token) : '');
const parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64)
        .split('')
        .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    })
        .join(''));
    return JSON.parse(jsonPayload);
};
token.subscribe((value) => {
    localStorage.setItem('token', value ? value : '');
});
