<script lang="ts">
  /* Global dependencies */
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";

  /* GSFT dependencies */
  import Layout from "../components/Layout.svelte";
  import FormColumn from "../components/violations/FormColumn.svelte";
  import Menu from "../components/violations/MenuColumn.svelte";
  import List from "../components/violations/ListColumn.svelte";
  import { getViolations, deleteViolation } from "../api/violations";
  import type { ViolationRecord, ChairUmpire } from "../types/violations";
  import { getGsChairUmpires } from "../api/chair-umpires";
  import { getGsCourts } from "../api/courts";
  import { dictionaries as dictionariesStore } from "../store/dictionaries";
  import type { GetDictionariesResult } from "../types/dictionaries";
  import type { GsCourt } from "../types/courts";
  import CreateViolationRecordModal from "../components/violations/CreateViolationRecordModal.svelte";
  import Spinner from "../components/Spinner.svelte";

  /* Props */
  export let params = { filter: "", id: null };

  /* Component */
  let loading = false;
  let title = "";
  let createViolationRecord = false;

  let violations: Array<ViolationRecord>;
  let chairUmpires: Array<ChairUmpire>;
  let courts: Array<GsCourt>;
  let dictionaries: GetDictionariesResult;
  let selectedRecord: ViolationRecord = null;

  $: violationsFiltered =
    violations &&
    violations.map(el => { el.selectedToDelete = false; return el; }).filter((el) => {
      if (params.filter !== "my") {
        return el.status.toLowerCase() == params.filter.toLowerCase();
      }
      
      return el;
    });

  $: {
    if (Number.isInteger(+params.filter)) {
      push(`/violations/my/${+params.filter}`);
    }
    if (params.filter === "my") title = "My Cases";
    else if (params.filter === "draft") title = "Draft Cases";
    else if (params.filter === "open") title = "Open Cases";
    else if (params.filter === "complete") title = "Completed Cases";
    else if (params.filter === "closed") title = "Closed cases";
  }

  $: {
    if (params.filter) {
      selectedRecord = null;
    }
  }

  $: {
    if (params.id && violations) {
      const record = violations.find((el) => +el.id === +params.id);
      selectedRecord = record;
    }
  }

  onMount(() => {
    loading = true;
    getViolations().then((ViolationRecord: Array<ViolationRecord>) => {
      violations = ViolationRecord;
      loading = false;
    });

    getGsChairUmpires().then((chairUmpiresResult: Array<ChairUmpire>) => {
      chairUmpires = chairUmpiresResult.map((el) => ({
        ...el,
        name: `${el.firstName} ${el.lastName}`,
      }));
    });

    getGsCourts().then((courtsResult: Array<GsCourt>) => {
      courts = courtsResult;
    });
  });

  dictionariesStore.subscribe((dictionariesResult: GetDictionariesResult) => {
    if (dictionariesResult) {
      dictionaries = dictionariesResult;
    }
  });

  const selectViolationRecord = (event: CustomEvent) => {
    const { record } = event.detail;
    if (record) {
      selectedRecord = record;
    }
  };

  const deleteViolationRecord = (event: CustomEvent)=>{
    const { records } = event.detail;
    if (records) {
      records.forEach(record => {
        const recordIndex = violations.findIndex((el) => el.id === record.id);
        violations.splice(recordIndex, 1);
      });
    }
    violations = violations;
    selectedRecord = null;
  }

  const searchViolationRecords = (event: CustomEvent)=>{
    
    const { searchPlayerName, searchMatchId, searchMatchDate, searchViolationType } = event.detail;
    
    
    var result = violations.filter((el) => {
      if (params.filter !== "my") {
        return el.status.toLowerCase() == params.filter.toLowerCase();
      }
      return el;
    }).filter((el) => {

      var found = false;

      

      var pnFound = false;
      if(searchPlayerName != null && searchPlayerName != ''){
        
        el.offendingPlayers.forEach(player => {
          var fullName = player.firstName.toLowerCase() + ' ' + player.lastName.toLowerCase();

          pnFound = pnFound || fullName.includes(searchPlayerName.toLowerCase())
          || player.shortName.toLowerCase().includes(searchPlayerName.toLowerCase());
        });

        el.opponentPlayers.forEach(player => {
          var fullName = player.firstName.toLowerCase() + ' ' + player.lastName.toLowerCase();
          pnFound = pnFound || fullName.includes(searchPlayerName.toLowerCase())
          || player.shortName.toLowerCase().includes(searchPlayerName.toLowerCase());
        });

      }
      else{
        pnFound = true;
      }


      found = pnFound;

      if(searchMatchId != null && searchMatchId != ''){
        found = found && (el.matchId.toLowerCase().includes(searchMatchId.toLowerCase())) 
      }

      if(searchMatchDate != null)
        found = found && (new Date(el.matchDate).getTime() == searchMatchDate.getTime())
      
      var vtFound = false;
      if(searchViolationType != null && searchViolationType != ''){
         el.violations.forEach(v => {
           vtFound = vtFound || v.violationType == searchViolationType
         });
      }
      else{
        vtFound = true;
      }

      found = found && vtFound;
      
      return found;
    });
    violationsFiltered = result;
    selectedRecord = null;
  }

  const updateViolationRecord = (event: CustomEvent) => {
    const { record } = event.detail;
    if (record) {
      const recordIndex = violations.findIndex((el) => el.id === record.id);
      violations[recordIndex] = record;
    }
    violations = violations;
  };

  const addRecordToList = (event: CustomEvent) => {
    if (event.detail.data) {
      violations = [event.detail.data, ...violations];
    }
  };
</script>

{#if createViolationRecord}
  <CreateViolationRecordModal
    {chairUmpires}
    {courts}
    {dictionaries}
    bind:open={createViolationRecord}
    on:success={addRecordToList}
  />
{/if}
<Layout noPadding>
  <section class="d-flex flex-column">
    <div class="row flex-grow-1 g-0 border-bottom">
      <div class="col-2 menu-column">
        <Menu params={params} />
      </div>
      <div class="col-4 list-column">
        <Spinner {loading} />
        {#if dictionaries}
        <List
          {title}
          {dictionaries}
          violations={violationsFiltered}
          on:select={selectViolationRecord}
          on:create={() => (createViolationRecord = true)}
          on:delete={deleteViolationRecord}
          on:filter={searchViolationRecords}
        />
        {/if}
      </div>
      <div class="col-6 form-column">
        {#if selectedRecord && chairUmpires && dictionaries}
          {#key selectedRecord.id + selectedRecord.status}
            <FormColumn
              {selectedRecord}
              {courts}
              {chairUmpires}
              {dictionaries}
              on:update={updateViolationRecord}
              on:delete={deleteViolationRecord}
            />
          {/key}
        {/if}
        {#if !selectedRecord}
          <h3 class="align-middle text-center mt-5">Please select case</h3>
        {/if}
      </div>
    </div>
  </section>
</Layout>

<style>
  section {
    min-height: calc(100vh - 180px);
  }
  .list-column {
    border-left: 1px solid var(--gsft-border-color);
    border-right: 1px solid var(--gsft-border-color);
    overflow: auto;
    position: relative;
  }
</style>
